import React from 'react'
import { Link } from 'react-router-dom'

export default function account() {
  return (
    <>
      <div className='dashboard'>
        <div className="titleanddetail">
          <div className="pageslug">
            <a href="#">Account</a>
          </div>
        </div>
        <div className='col-md-12'>
          <div className='menus'>
            <ul>
              <li><Link to='/genarel-setting' >General</Link></li>
              <li><Link to='/account-setting'>Setting</Link></li>
              <li><a href='#' className='active'>Security</a></li>

            </ul>
          </div>
        </div>
        <div className='col-md-12 mt-3 changepass'>
          <div className='col-md-4'>
            <div className="titleanddetail ">
              <div className="pageslug">
                <a href="#">Change Password</a>
              </div>
            </div>
            <p>If you need to change your password, you can do so easily and conveniently. </p>
          </div>
          <div className='col-md-8'>
            <div className="addpropertyForm">



              <form className="row g-3">


                <div className="col-md-12">
                  <label for="inputEmail4" className="form-label">Current Password</label>
                  <input type="text" className="form-control" id="inputPassword4" placeholder="Input current password" />
                </div>
                <div className="col-md-12">
                  <label for="inputEmail4" className="form-label">New Password</label>
                  <input type="text" className="form-control" id="inputPassword4" placeholder="Input new password" />
                </div>
                <div className="col-md-12">
                  <label for="inputEmail4" className="form-label">New Password</label>
                  <input type="text" className="form-control" id="inputPassword4" placeholder="Input new password" />
                </div>



                <div className="col-md-12 d-flex justify-content-end">

                  <button type="submit" className="btn btn-primary">Update Password</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='col-md-12 mt-5 device'>
          <div className="titleanddetail">
            <div className="pageslug">
              <a href="#">Your Devices</a>
            </div>
            <div className='right'>Log out on all devices</div>
          </div>
          <ul className='device-list'>
            <li>
              <div className='left'>
                <div className='image'><img src={'../lbs/images/desktop.png'} /></div>
                <div className='details'>
                  <div className='name'>Desktop 1</div>
                  <div className='lastonline'>August 12 at 17.45</div>
                </div>
              </div>
              <div className='right'><button className='active'>Log Out</button></div>
            </li>
            <li>
              <div className='left'>
                <div className='image'><img src={'../lbs/images/desktop.png'} /></div>
                <div className='details'>
                  <div className='name'>Desktop 1</div>
                  <div className='lastonline'>August 12 at 17.45</div>
                </div>
              </div>
              <div className='right'><button className=''>Log Out</button></div>
            </li>
            <li>
              <div className='left'>
                <div className='image'><img src={'../lbs/images/desktop.png'} /></div>
                <div className='details'>
                  <div className='name'>Desktop 1</div>
                  <div className='lastonline'>August 12 at 17.45</div>
                </div>
              </div>
              <div className='right'><button className='active'>Log Out</button></div>
            </li>
          </ul>
        </div>

      </div>
    </>
  )
}
