import logo from './logo.svg';
import './App.css';
import Home from './components/pages/home';
import Property from './components/pages/property';
import Propertydetails from './components/pages/propertydetails';
import Propertymodify from './components/pages/propertymodify';
import Propertylist from './components/pages/propertylist';
import Addproperty from './components/pages/addproperty';
import Addticket from './components/pages/addticket';
import Contact from './components/pages/contact';
import Forgetpass from './components/pages/forgetpass';
import Myticket from './components/pages/myticket';
import Setnewpass from './components/pages/setnewpass';
import Registration from './components/pages/registration';
import Login from './components/pages/login';
import Header from './components/main/header';
import Sidebar from './components/main/sidebar';
import Propertysidebar from './components/pages/property_manager/sidebar';
import Error from './components/pages/error';
import Facilitydb from './components/pages/facility_manager/db';
import Facilitycreate from './components/pages/facility_manager/create';
import Facilityview from './components/pages/facility_manager/overview';
import Facilityedit from './components/pages/facility_manager/edit';
import Facilityrequestedit from './components/pages/facility_manager/requestview';
import Facilitytechnicalticket from './components/pages/facility_manager/technicalticket';
import Facilitychat from './components/pages/facility_manager/chat';
import FacilitySidebar from './components/pages/facility_manager/sidebar';
import Propertytechnical from './components/pages/property_manager/technical';
import Propertycontract from './components/pages/property_manager/contract';
import Propertycreatecontract from './components/pages/property_manager/createcontract';
import Propertypaintingprocess from './components/pages/property_manager/paintingoverview';
import Propertyapprovalprocess from './components/pages/property_manager/approvalprocess';
import Propertycontractprocess from './components/pages/property_manager/contractprocess';
import Propertychat from './components/pages/property_manager/chat';
import Notification from './components/pages/notification';
import Propertybudget from './components/pages/property_manager/budget';
import Propertjour from './components/pages/property_manager/jour';
import Jourview from './components/pages/property_manager/jourview';
import Accountsecuritysetting from './components/pages/account/account';
import AccountGenarelsetting from './components/pages/account/genaccount';
import Accountsetting from './components/pages/account/setting';
import Performance from './components/pages/performance';
import Performancedetail from './components/pages/performancedetail';
import Jourflexcreatenew from './components/pages/property_manager/jourflexcreatenew';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import property from './components/pages/property';
function App() {
  return (
    <>


      <BrowserRouter>



        <Routes>

          <Route path="/home" exact element={
            <>
              <Sidebar />
              <Header />
              <div className='mainSection'>
                <Home />
              </div>
            </>
          } />
          <Route path="/" exact element={
            <>
              <Sidebar />
              <Header />
              <div className='mainSection'>
                <Home />
              </div>
            </>
          } />
          <Route path="/property-details-2" exact element={
            <>
              <Sidebar />
              <Header />
              <div className='mainSection'>
                <Property />
              </div>
            </>
          } />
          <Route path="/property-list" exact element={
            <>
              <Sidebar />
              <Header />
              <div className='mainSection'>
                <Propertylist />
              </div>
            </>
          } />
          <Route path="/property-details" exact element={
            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Propertydetails />
              </div>
            </>
          } />
          <Route path="/property-modify" exact element={
            <>
              <Sidebar />
              <Header />
              <div className='mainSection'>
                <Propertymodify />
              </div>
            </>
          } />
          <Route path="/addproperty" exact element={
            <>
              <Sidebar />
              <Header />
              <div className='mainSection'>
                <Addproperty />
              </div>
            </>
          } />
          <Route path="/addticket" exact element={
            <>
              <Sidebar />
              <Header />
              <div className='mainSection'>
                <Addticket />
              </div>
            </>
          } />
          <Route path="/contact" exact element={
            <>
              <Sidebar />
              <Header />
              <div className='mainSection'>
                <Contact />
              </div>
            </>
          } />
          <Route path="/myticket" exact element={
            <>
              <Sidebar />
              <Header />
              <div className='mainSection'>
                <Myticket />
              </div>
            </>
          } />
          <Route path="/notification" exact element={
            <>
              <Sidebar />
              <Header />
              <div className='mainSection'>
                <Notification />
              </div>
            </>
          } />
          <Route path="/forgetpass" exact element={<Forgetpass />} />
          <Route path="/setnewpass" exact element={<Setnewpass />} />
          <Route path="/registration" exact element={<Registration />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/facility-manager" exact element={

            <>
              <FacilitySidebar />
              <Header />
              <div className='mainSection'>
                <Facilitydb />
              </div>
            </>
          } />
          <Route path="/facility-manager-add" exact element={

            <>
              <FacilitySidebar />
              <Header />
              <div className='mainSection'>
                <Facilitycreate />
              </div>
            </>
          } />
          <Route path="/facility-manager-view" exact element={

            <>
              <FacilitySidebar />
              <Header />
              <div className='mainSection'>
                <Facilityview />
              </div>
            </>
          } />
          <Route path="/facility-manager-modification-requests" exact element={

            <>
              <FacilitySidebar />
              <Header />
              <div className='mainSection'>
                <Facilityedit />
              </div>
            </>
          } />
          <Route path="/facility-manager-modification-requests-view" exact element={

            <>
              <FacilitySidebar />
              <Header />
              <div className='mainSection'>
                <Facilityrequestedit />
              </div>
            </>
          } />
          <Route path="/facility-technical-ticket" exact element={

            <>
              <FacilitySidebar />
              <Header />
              <div className='mainSection'>
                <Facilitytechnicalticket />
              </div>
            </>
          } />
          <Route path="/facility-chat" exact element={

            <>
              <FacilitySidebar />
              <Header />
              <div className='mainSection'>
                <Facilitychat />
              </div>
            </>
          } />





          <Route path="/propertys" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Propertytechnical />
              </div>
            </>
          } />
          <Route path="/property-manager-add" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Facilitycreate />
              </div>
            </>
          } />
          <Route path="/property-contract" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Propertycontract />
              </div>
            </>
          } />
          <Route path="/property-contract-create" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Propertycreatecontract />
              </div>
            </>
          } />
          <Route path="/property-painting-process" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Propertypaintingprocess />
              </div>
            </>
          } />
          <Route path="/property-property-approval-process" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Propertyapprovalprocess />
              </div>
            </>
          } />
          <Route path="/property-property-contract-process" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Propertycontractprocess />
              </div>
            </>
          } />
          <Route path="/property-chat" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Propertychat />
              </div>
            </>
          } />
          <Route path="/Property-budget" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Propertybudget />
              </div>
            </>
          } />
          <Route path="/Property-jour" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Propertjour />
              </div>
            </>
          } />
          <Route path="/jour-view" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Jourview />
              </div>
            </>
          } />
      
          <Route path="/genarel-setting" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <AccountGenarelsetting />
              </div>
            </>
          } />
          <Route path="/account-setting" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Accountsetting />
              </div>
            </>
          } />
          <Route path="/security-setting" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Accountsecuritysetting />
              </div>
            </>
          } />
          <Route path="/performance" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Performance />
              </div>
            </>
          } />
          <Route path="/performance-detail" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Performancedetail />
              </div>
            </>
          } />
          <Route path="/jour-flex-create-new" exact element={

            <>
              <Propertysidebar />
              <Header />
              <div className='mainSection'>
                <Jourflexcreatenew />
              </div>
            </>
          } />






          <Route path="*" exact element={<Error />} />



        </Routes>



      </BrowserRouter>



    </>
  );
}

export default App;
