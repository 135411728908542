import React from 'react'
import { Link } from 'react-router-dom'

export default function performancedetail() {
    const linkredirect = (e) => {

        window.location.href = '/property-painting-process';
    }
    return (
        <>
            <div className="dashboard performance">

                <div className="titleanddetail performance-header">
                    <div className='col-md-7 d-flex'>
                        <div className="pageslug">
                            <a>Lakewood Property - Status and Performance Overview
                            </a>
                        </div>
                    </div>
                    <div className='col-md-5 d-flex align-items-center'>
                        <div className='col-md-8'>

                            <Link to="/performance" className='btn2'>Overview</Link>
                            <a className='btn1'>Detail</a>
                        </div>
                        <div className='col-md-3 justify-content-end'>
                            <select id="inputState" className="form-select">
                                <option selected="">ENG</option>

                            </select>
                            <a className='btn3 mt-2'>Export</a>
                        </div>
                    </div>

                </div>
                <div className='performance-table-container'>
                    <div className='titleandsub m-2 mb-4 '>
                        <div className="title">Task arising from approval process
                        </div>

                    </div>
                    <div className="tenantsTable">
                        <table className="table teantsTableinpropertydetails propertydetails2 performance">
                            <thead>
                                <tr>
                                    <th scope="col">Process</th>
                                    <th scope="col">Assigned To</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Budget</th>
                                    <th scope="col">Process Creation</th>
                                    <th scope="col">Process Light</th>

                                </tr>
                            </thead>
                            <tbody>

                                <tr onClick={linkredirect}>

                                    <td>Painting Maintenance</td>
                                    <td>Facility Manager</td>
                                    <td>$250</td>
                                    <td>$250</td>
                                    <td>15.05.2022</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>Painting Maintenance</td>
                                    <td>Facility Manager</td>
                                    <td>$250</td>
                                    <td>$250</td>
                                    <td>15.05.2022</td>

                                    <td>
                                        <div className='status-btn green'></div>
                                        <div className='status-btn yellow active'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>Painting Maintenance</td>
                                    <td>Facility Manager</td>
                                    <td>$250</td>
                                    <td>$250</td>
                                    <td>15.05.2022</td>

                                    <td>
                                        <div className='status-btn green'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red active'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>Painting Maintenance</td>
                                    <td>Facility Manager</td>
                                    <td>$250</td>
                                    <td>$250</td>
                                    <td>15.05.2022</td>

                                    <td>
                                        <div className='status-btn green'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>Painting Maintenance</td>
                                    <td>Facility Manager</td>
                                    <td>$250</td>
                                    <td>$250</td>
                                    <td>15.05.2022</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>Painting Maintenance</td>
                                    <td>Facility Manager</td>
                                    <td>$250</td>
                                    <td>$250</td>
                                    <td>15.05.2022</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>





                            </tbody>
                        </table>
                    </div>
                    <div className='pagination-2'>
                        <ul>
                            <li className='next'></li>
                            <li className='active'>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li className='previous'></li>
                        </ul>
                    </div>

                </div>
                <div className='performance-table-container'>
                    <div className='titleandsub m-2 mb-4 '>
                        <div className="title">Task arising from Jour Fixe
                        </div>

                    </div>
                    <div className="tenantsTable">
                        <table className="table teantsTableinpropertydetails propertydetails2 performance">
                            <thead>
                                <tr>
                                    <th scope="col">Sr.</th>
                                    <th scope="col">Theme</th>
                                    <th scope="col">Measure</th>
                                    <th scope="col">To-Do</th>
                                    <th scope="col">Completion Date</th>
                                    <th scope="col">Process Light</th>

                                </tr>
                            </thead>
                            <tbody>

                                <tr onClick={linkredirect}>

                                    <td>01</td>
                                    <td>Theme title here</td>
                                    <td>Measure title here</td>
                                    <td>Measure title here</td>
                                    <td>10.10.2022</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>01</td>
                                    <td>Theme title here</td>
                                    <td>Measure title here</td>
                                    <td>Measure title here</td>
                                    <td>10.10.2022</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>01</td>
                                    <td>Theme title here</td>
                                    <td>Measure title here</td>
                                    <td>Measure title here</td>
                                    <td>10.10.2022</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>01</td>
                                    <td>Theme title here</td>
                                    <td>Measure title here</td>
                                    <td>Measure title here</td>
                                    <td>10.10.2022</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                        




                            </tbody>
                        </table>
                    </div>
                    <div className='pagination-2'>
                        <ul>
                            <li className='next'></li>
                            <li className='active'>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li className='previous'></li>
                        </ul>
                    </div>

                </div>
                <div className='performance-table-container'>
                    <div className='titleandsub m-2 mb-4 '>
                        <div className="title">Task arising from Ticket
                        </div>

                    </div>
                    <div className="tenantsTable">
                        <table className="table teantsTableinpropertydetails propertydetails2 performance">
                            <thead>
                                <tr>
                                    <th scope="col">Sr.</th>
                                    <th scope="col">Ticket Creator</th>
                                    <th scope="col">Theme</th>
                                    <th scope="col">Ticket Creation Date</th>
                                    <th scope="col">No Feedback Since</th>
                                    <th scope="col">Process Light</th>

                                </tr>
                            </thead>
                            <tbody>

                                <tr onClick={linkredirect}>

                                    <td>01</td>
                                    <td>John Smith</td>
                                    <td>Theme title here</td>
                                    <td>$250</td>
                                    <td>10.12.2022</td>
                                    <td>10.10.2022</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                        
                                <tr onClick={linkredirect}>

                                    <td>01</td>
                                    <td>John Smith</td>
                                    <td>Theme title here</td>
                                    <td>$250</td>
                                    <td>10.12.2022</td>
                                    <td>10.10.2022</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                        
                                <tr onClick={linkredirect}>

                                    <td>01</td>
                                    <td>John Smith</td>
                                    <td>Theme title here</td>
                                    <td>$250</td>
                                    <td>10.12.2022</td>
                                    <td>10.10.2022</td>

                                    <td>
                                        <div className='status-btn green'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red active'></div>
                                    </td>

                                </tr>
                        
                                <tr onClick={linkredirect}>

                                    <td>01</td>
                                    <td>John Smith</td>
                                    <td>Theme title here</td>
                                    <td>$250</td>
                                    <td>10.12.2022</td>
                                    <td>10.10.2022</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                        
                                <tr onClick={linkredirect}>

                                    <td>01</td>
                                    <td>John Smith</td>
                                    <td>Theme title here</td>
                                    <td>$250</td>
                                    <td>10.12.2022</td>
                                    <td>10.10.2022</td>

                                    <td>
                                        <div className='status-btn green'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red active'></div>
                                    </td>

                                </tr>
                        



                            </tbody>
                        </table>
                    </div>
                    <div className='pagination-2'>
                        <ul>
                            <li className='next'></li>
                            <li className='active'>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li className='previous'></li>
                        </ul>
                    </div>

                </div>
                <div className='performance-table-container'>
                    <div className='titleandsub m-2 mb-4 '>
                        <div className="title">Budget Overview
                        </div>

                    </div>
                    <div className="tenantsTable">
                        <table className="table teantsTableinpropertydetails propertydetails2 performance">
                            <thead>
                                <tr>
                                    <th scope="col">Actual Cost</th>
                                    <th scope="col">Budget  Sum To</th>
                                    <th scope="col">Reference date</th>
                                    <th scope="col">Deficit</th>
                                    <th scope="col">Deficit %</th>
                                    <th scope="col">Process Light</th>

                                </tr>
                            </thead>
                            <tbody>

                                <tr onClick={linkredirect}>

                                    <td>500.000</td>
                                    <td><div className='color-primary'>Q1  2022</div>400.000</td>
                                    <td>10.12.2022 </td>
                                    <td>100.00</td>
                                    <td>10%</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>500.000</td>
                                    <td><div className='color-primary'>Q1  2022</div>400.000</td>
                                    <td>10.12.2022 </td>
                                    <td>100.00</td>
                                    <td>10%</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>500.000</td>
                                    <td><div className='color-primary'>Q1  2022</div>400.000</td>
                                    <td>10.12.2022 </td>
                                    <td>100.00</td>
                                    <td>10%</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>500.000</td>
                                    <td><div className='color-primary'>Q1  2022</div>400.000</td>
                                    <td>10.12.2022 </td>
                                    <td>100.00</td>
                                    <td>10%</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>500.000</td>
                                    <td><div className='color-primary'>Q1  2022</div>400.000</td>
                                    <td>10.12.2022 </td>
                                    <td>100.00</td>
                                    <td>10%</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>500.000</td>
                                    <td><div className='color-primary'>Q1  2022</div>400.000</td>
                                    <td>10.12.2022 </td>
                                    <td>100.00</td>
                                    <td>10%</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                                <tr onClick={linkredirect}>

                                    <td>500.000</td>
                                    <td><div className='color-primary'>Q1  2022</div>400.000</td>
                                    <td>10.12.2022 </td>
                                    <td>100.00</td>
                                    <td>10%</td>

                                    <td>
                                        <div className='status-btn green active'></div>
                                        <div className='status-btn yellow'></div>
                                        <div className='status-btn red'></div>
                                    </td>

                                </tr>
                    





                            </tbody>
                        </table>
                    </div>
                    <div className='pagination-2'>
                        <ul>
                            <li className='next'></li>
                            <li className='active'>1</li>
                            <li>2</li>
                            <li>3</li>
                            <li className='previous'></li>
                        </ul>
                    </div>

                </div>

            </div>
        </>
    )
}
