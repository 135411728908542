import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function registration() {
  return (
    <>
    
    <div className="page">
        <div className="loginpage">
            <div className="leftsection">
               <div className="logo">
                    <img src={"/lbs/images/vlrlogo.png"} alt=""/>
               </div>
               <div className="page-sub-heading">
                   <p>Welcome to JamesHelps! After logging in, I will be happy to help.</p>
               </div>
            </div>
            <div className="rightsection">
                    <div className="formcontainer">
                        <div className="pagetitle">Registration</div>
                        <form action="?" method="post">
                              <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">Name</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1"/>
                              </div>
                              <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="exampleFormControlInput1"/>
                              </div>
                              <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">Password</label>
                                <input type="password" className="form-control" id="exampleFormControlInput1"/>
                              </div>
                              <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">Retype Password</label>
                                <input type="password" className="form-control" id="exampleFormControlInput1"/>
                              </div>
                             
                              <button type="submit" className="btn btn-primary">Sign Up</button>
                        </form>
                     
                        <div className="d-flex justify-content-center needaccount">If you have an account?  <Link to="/login"> Login</Link></div>
                    </div>
            </div>
        </div>
    </div>

    </>
  )
}
