import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function myticket() {
  const link = (e) => {

    window.location.href = "/contact";
}
  return (
    <>
      <div className="dashboard my-ticket">
        <div className="col-md-12 addpropertyForm row">
          <div className="col-md-6 d-flex justify-content-start">
            <button type="submit" className="btn2 btn-primary">My Tickets</button>
            <button type="submit" className="btn3 btn-primary">Tenant Tickets</button>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <button type="submit" className="btn2 btn-primary">Export</button>
            <Link to="/addticket"><button type="submit" className="btn btn-primary">Create New Ticket</button></Link>
          </div>
        </div>
        <div className="col-md-12">
          <div className="menulist">
            <ul>
              <li><a href="#" className="active">All</a></li>
              <li><a href="#">Contract Inquiries</a></li>
              <li><a href="#">Tenant Services</a></li>
              <li><a href="#">Deficiency Message</a></li>
              <li><a href="#">Other Request</a></li>
            </ul>
          </div>
          <div className="filterlist">
            <ul>
              <li className="active"><a href="#">Open</a></li>
              <li><a href="#">Close</a></li>
              <li><a href="#">In Progress</a></li>

            </ul>
          </div>
          <div className="tenantsTablemyticket" >
            <table className="table teantsTableinpropertydetails myticket">
              <thead>
                <tr>
                  <th scope="col">Requester</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Status</th>
                  <th scope="col">Last Message</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr onClick={link} style={{cursor:'pointer'}}>
                  <th scope="row"><img src={"/lbs/images/profile.png"} alt="" />
                    <span className="moredetails">
                      <div className="name">David Smith</div>
                      <div className="email">davidsmith@gmail.com</div>
                    </span>
                  </th>
                  <td >Ac sit etiam velit, consequat est augue commodo non.</td>
                  <td><button className="status-btn in-progress">In Progress</button></td>
                  <td>4 seconds ago</td>
                  <td><i className="fa-solid fa-ellipsis"></i></td>
                </tr>




              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}
