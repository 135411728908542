import React from 'react'
import { Link } from 'react-router-dom'
export default function createcontract() {
  return (
    <>
     <div className="dashboard propertyDetailspage requestview-modification">

<div className="titleanddetail">
    <div className="pageslug">
        <li><Link to="#">Approval Process </Link></li>
        <li><Link to="#"> Contract Process</Link></li>
    </div>

</div>


<div className="addpropertyForm">

   <div className="formTitle">
   Create new approval process
   </div>


<form className="row g-3">
  <div className="col-md-12">
    <label for="inputEmail4" className="form-label">Property *</label>
    <select id="inputState" className="form-select">
      <option selected="">Select Option</option>
      <option>...</option>
    </select>
  </div>
  <div className="col-md-6">
    <label for="inputEmail4" className="form-label">Process Type *</label>
    <select id="inputState" className="form-select">
      <option selected="">Select Option</option>
      <option>...</option>
    </select>
  </div>
  <div className="col-md-6">
    <label for="inputEmail4" className="form-label">Assign To *</label>
    <select id="inputState" className="form-select">
      <option selected="">Select Option</option>
      <option>...</option>
    </select>
  </div>
  <div className="col-md-6">
    <label for="inputEmail4" className="form-label">Cost *</label>
    <select id="inputState" className="form-select">
      <option selected="">Select Option</option>
      <option>...</option>
    </select>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Budget *</label>
    <select id="inputState" className="form-select">
      <option selected="">Select Option</option>
      <option>...</option>
    </select>
  </div>
  <div className="col-12">
    <label for="inputAddress" className="form-label">Description *</label>
    <textarea name="" id="" cols="30" rows="10" placeholder="Type here"  className="form-control"></textarea>
  </div>
  
 
  <div className="col-12 d-flex justify-content-end">
    <button type="submit" className="btn btn-primary">Create </button>
  </div>
</form>
</div>



</div>
    </>
  )
}
