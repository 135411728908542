import React from 'react'
import { Link } from 'react-router-dom'

export default function jourview() {
    return (
        <>
            <div className="dashboard propertyDetailspage approvalprocess jour-view">

                <div className="titleanddetail">
                    <div className="pageslug">
                        <li><Link to="#">Approval Process </Link></li>
                        <li><Link to="#">Painting Process</Link></li>
                    </div>

                </div>
                <div className='propertyList '>

                    <Link to="/property-details" className="property">
                        <div className="thumbanil">
                            <img src={"./lbs/images/Property1.png"} alt="" />
                        </div>
                        <div className="detail">
                            <ul>
                                <li><label for="Country">Land:</label>
                                    <div className="detail">Deutschland</div>
                                </li>
                                <li><label for="City">Stadt:</label>
                                    <div className="detail">Frankfurt am Main</div>
                                </li>
                                <li><label for="Street">Straße: </label>
                                    <div className="detail">Gartenstraße  1</div>
                                </li>
                                <li><label for="Address:">Addresse:</label>
                                    <div className="detail">Gartenstraße 1 in 60594 Frankfurt am Main</div>
                                </li>
                                <li><label for="Building">Objekt Nr.: </label>
                                    <div className="detail">01</div>
                                </li>
                                {/* <li><label for="Squaremeters">Squaremeters:</label>
                    <div className="detail">272sqm</div>
                </li>
                <li><label for="Floor">Floor:</label>
                    <div className="detail">Floor #1</div>
                </li> */}
                                {/* <li><label for="Number of Tenants:">Number of Tenants:</label>
    <div className="detail">8</div>
</li>
<li><label for="Contact Security Person of Property">Contact Security Person of Property:</label>
    <div className="detail">(406) 555-0120</div>
</li> */}
                            </ul>
                        </div>
                    </Link>

                </div>
                <div className="row">
                    <div className="col-md-12">

                        <div className="titleanddis contactDetailsbyposition property-jour">
                            <div className="title normal">Process Overview <span style={{ color: '#39A207', background: '#eff8e3', fontSize: '12px', padding: '5px 12px', borderRadius: '10px' }}>Ongoing</span></div>
                            <div className="dis">
                                <div className="detail">
                                    <ul>
                                        <li><label for="Country">Host:</label>
                                            <div className="detail">Mia Smith</div>

                                        </li>
                                        <li><label for="Country">Moderator:</label>
                                            <div className="detail">Mia Smith</div>

                                        </li>
                                        <li><label for="Country">Thema:</label>
                                            <div className="detail">Vertragsthemen mit den Mietern</div>

                                        </li>
                                        <li><label for="Country">Teilnehmer:</label>
                                            <div className="detail">Mia Smith, John, David, Sarah</div>

                                        </li>
                                        <li><label for="Country">Dauer:</label>
                                            <div className="detail">45 Minuten</div>

                                        </li>



                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="titleanddis contactDetailsbyposition property-jour jour-view" >

                            <div className="dis">

                                <div className="tenantsTable">
                                    <table className="table teantsTableinpropertydetails propertydetails2">
                                        <thead>
                                            <tr>
                                                <th scope="col">Notizen</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Meier Marketing Agentur: Aktuelle keine Vertragsthemen.</td>


                                            </tr>
                                            <tr>
                                                <td>Huber Ingenieur GmbH: </td>


                                            </tr>
                                            <tr>
                                                <td> 1.Offener Widerspruch der Nebenkostenabrechnung. Unsere Buchhaltung hat das Widerspruchsschreiben beantwortet. Sobald wir eine Rückmeldung erhalten, werden wir eine kurze Nachricht im Channel #HuberMieter bereitstellen.</td>

                                            </tr>
                                            <tr>
                                                <td>Rechtsanwalt Karl & Kollegen: </td>


                                            </tr>
                                            <tr>
                                                <td>  1.Verhandlungsstand des Nachtragen Nr.3 - Herr Karl hat uns den Nachtrag einseitig unterschrieben zurück geschickt. Wir werden diesen morgen an den Investor in Luxembourg versenden.</td>

                                            </tr>
                                            <tr>
                                                <td></td>
                                                
                                            </tr>
                                            <tr>
                                                <td>Aktuell gibt es keine weiteren Themen.</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>Relevante Channels: <Link to="#"> #HuberMieter</Link> ; <Link to="#">#KarlMieter</Link> ; <Link to="#">#MeierMieter</Link></td>
                                                
                                            </tr>



                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>



            </div>
        </>
    )
}
