import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function home() {
  return (
    <>
<div className="dashboard">
      <div className="cards">
          <div className="card" style={{width : "18rem"}}>
          <div className="row hd">
            <div className="icon"><img src={"/lbs/images/icon1.png"} alt=""/></div>
            <div className="name">Properties</div>
          </div>
          <div className="row nv">
            <div className="dis">Totol number of Properties</div>
            <div className="number">24</div>

          </div>
        </div>
          <div className="card" style={{width : "18rem"}}>
          <div className="row hd">
            <div className="icon" style={{backgroundColor : " #e7f4ed;"}}><img src={"/lbs/images/Tenant.png"} alt=""/></div>
            <div className="name">Tenant</div>
          </div>
          <div className="row nv">
            <div className="dis">Totol number of Tenants</div>
            <div className="number" style={{color: "#34a265;"}}>24</div>

          </div>
        </div>
          <div className="card" style={{width : "18rem"}}>
          <div className="row hd">
            <div className="icon" style={{backgroundColor : " #feebeb;"}}><img src={"/lbs/images/Tickets.png"} alt=""/></div>
            <div className="name">Tickets</div>
          </div>
          <div className="row nv">
            <div className="dis">Totol number of tickets</div>
            <div className="number" style={{color: "#e21b1b;"}}>24</div>

          </div>
        </div>
          <div className="card" style={{width : "18rem"}}>
          <div className="row hd">
            <div className="icon" style={{backgroundColor : " #e4eaeb;"}}><img src={"/lbs/images/Requests.png"} alt=""/></div>
            <div className="name">Other Requests</div>
          </div>
          <div className="row nv">
            <div className="dis">Totol number of requests</div>
            <div className="number" style={{color: "#3453A2;"}}>24</div>

          </div>
        </div>
      </div>
      <div className="titleanddetail">
        <span className="title">Property Details</span>
        <Link to="/property-list" className="detail">View All</Link>
      </div>
      <div className="propertyList">
        <Link to="/property-details" className="property">
          <div className="thumbanil">
            <img src={"/lbs/images/Property1.png"} alt=""/>
          </div>
          <div className="detail">
            <ul>
              <li><label for="Country">Country:</label>
                <div className="detail">United States of America</div>
              </li>
              <li><label for="City">City:</label>
                <div className="detail">New York</div>
              </li>
              <li><label for="Street">Street:</label>
                <div className="detail">Street #1</div>
              </li>
              <li><label for="Address:">Address:</label>
                <div className="detail">4517 Washington Ave. Manchester, Kentucky 39495</div>
              </li>
              <li><label for="Building">Building:</label>
                <div className="detail">Building #1</div>
              </li>
              <li><label for="Squaremeters">Squaremeters:</label>
                <div className="detail">272sqm</div>
              </li>
              <li><label for="Floor">Floor:</label>
                <div className="detail">Floor #1</div>
              </li>
              <li><label for="Number of Tenants:">Number of Tenants:</label>
                <div className="detail">8</div>
              </li>
              <li><label for="Contact Security Person of Property">Contact Security Person of Property:</label>
                <div className="detail">(406) 555-0120</div>
              </li>
            </ul>
          </div>
        </Link>
        <Link to="/property-details" className="property">
          <div className="thumbanil">
            <img src={"/lbs/images/Property2.png"} alt=""/>
          </div>
          <div className="detail">
            <ul>
              <li><label for="Country">Country:</label>
                <div className="detail">United States of America</div>
              </li>
              <li><label for="City">City:</label>
                <div className="detail">New York</div>
              </li>
              <li><label for="Street">Street:</label>
                <div className="detail">Street #1</div>
              </li>
              <li><label for="Address:">Address:</label>
                <div className="detail">4517 Washington Ave. Manchester, Kentucky 39495</div>
              </li>
              <li><label for="Building">Building:</label>
                <div className="detail">Building #1</div>
              </li>
              <li><label for="Squaremeters">Squaremeters:</label>
                <div className="detail">272sqm</div>
              </li>
              <li><label for="Floor">Floor:</label>
                <div className="detail">Floor #1</div>
              </li>
              <li><label for="Number of Tenants:">Number of Tenants:</label>
                <div className="detail">8</div>
              </li>
              <li><label for="Contact Security Person of Property">Contact Security Person of Property:</label>
                <div className="detail">(406) 555-0120</div>
              </li>
            </ul>
          </div>
        </Link>
        <Link to="/property-details" className="property">
          <div className="thumbanil">
            <img src={"/lbs/images/Property3.png"} alt=""/>
          </div>
          <div className="detail">
            <ul>
              <li><label for="Country">Country:</label>
                <div className="detail">United States of America</div>
              </li>
              <li><label for="City">City:</label>
                <div className="detail">New York</div>
              </li>
              <li><label for="Street">Street:</label>
                <div className="detail">Street #1</div>
              </li>
              <li><label for="Address:">Address:</label>
                <div className="detail">4517 Washington Ave. Manchester, Kentucky 39495</div>
              </li>
              <li><label for="Building">Building:</label>
                <div className="detail">Building #1</div>
              </li>
              <li><label for="Squaremeters">Squaremeters:</label>
                <div className="detail">272sqm</div>
              </li>
              <li><label for="Floor">Floor:</label>
                <div className="detail">Floor #1</div>
              </li>
              <li><label for="Number of Tenants:">Number of Tenants:</label>
                <div className="detail">8</div>
              </li>
              <li><label for="Contact Security Person of Property">Contact Security Person of Property:</label>
                <div className="detail">(406) 555-0120</div>
              </li>
            </ul>
          </div>
        </Link>

      </div>
    </div>
    </>
  )
}
