import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function propertymodify() {
  return (
    <>
      <div className="dashboard propertyDetailspage">

        <div className="titleanddetail">
          <div className="pageslug">
            <li><a href="#">Properties</a></li>
            <li><a href="#">Property 1</a></li>
          </div>
          <a href="#" className="btn detail">Modify Deal</a>
        </div>
        <div className="row">
          <div className="col-md-12">

            <div className="titleanddis">
              <div className="title">Contract Details:</div>
              <div className="dis">
                <div className="detail">
                  <ul>
                    <li><label for="Country">Tenant:</label>
                      <div className="detail">Mia Smith</div>
                    </li>
                    <li><label for="City">Landlord:</label>
                      <div className="detail">Real Estate Company</div>
                    </li>
                    <li><label for="Street">Property:</label>
                      <div className="detail">House</div>
                    </li>
                    <li><label for="Address:">Payments:</label>
                      <div className="detail">3.000 € per month </div>
                    </li>
                    <li><label for="Building">Payment system:</label>
                      <div className="detail">Bank Transfer</div>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div className="titleanddis contactDetailsbyposition">
              <div className="title">Tenant Details:</div>
              <div className="dis">
                <div className="detail">
                  <ul>
                    <li><label for="Country">Tenant:</label>
                      <div className="detail">Mia Smith</div>
                    </li>
                    <li><label for="City">Email:</label>
                      <div className="detail">miasmith@gmail.com</div>
                    </li>
                    <li><label for="Street">Phone:</label>
                      <div className="detail">(254) 865 4585</div>
                    </li>


                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="tenantsTable">
          <table className="table teantsTableinpropertydetails propertydetails2">
            <thead>
              <tr>
                <th scope="col">Subject</th>
                <th scope="col">Status</th>
                <th scope="col">Last Message</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">Ac sit etiam velit, consequat est augue commodo non.</th>
                <td><button className="status-btn in-progress">In Progress</button></td>
                <td>4 seconds ago</td>
                <td><i className="fa-solid fa-ellipsis"></i></td>
              </tr>
              <tr>
                <th scope="row">Ac sit etiam velit, consequat est augue commodo non.</th>
                <td><button className="status-btn in-open">Open</button></td>
                <td>4 seconds ago</td>
                <td><i className="fa-solid fa-ellipsis"></i></td>
              </tr>
              <tr>
                <th scope="row">Ac sit etiam velit, consequat est augue commodo non.</th>
                <td><button className="status-btn in-close">Close</button></td>
                <td>4 seconds ago</td>
                <td><i className="fa-solid fa-ellipsis"></i></td>
              </tr>


              <tr>
                <th scope="row">Ac sit etiam velit, consequat est augue commodo non.</th>
                <td><button className="status-btn in-progress">In Progress</button></td>
                <td>4 seconds ago</td>
                <td><i className="fa-solid fa-ellipsis"></i></td>
              </tr>
              <tr>
                <th scope="row">Ac sit etiam velit, consequat est augue commodo non.</th>
                <td><button className="status-btn in-open">Open</button></td>
                <td>4 seconds ago</td>
                <td><i className="fa-solid fa-ellipsis"></i></td>
              </tr>
              <tr>
                <th scope="row">Ac sit etiam velit, consequat est augue commodo non.</th>
                <td><button className="status-btn in-close">Close</button></td>
                <td>4 seconds ago</td>
                <td><i className="fa-solid fa-ellipsis"></i></td>
              </tr>


              <tr>
                <th scope="row">Ac sit etiam velit, consequat est augue commodo non.</th>
                <td><button className="status-btn in-progress">In Progress</button></td>
                <td>4 seconds ago</td>
                <td><i className="fa-solid fa-ellipsis"></i></td>
              </tr>
              <tr>
                <th scope="row">Ac sit etiam velit, consequat est augue commodo non.</th>
                <td><button className="status-btn in-open">Open</button></td>
                <td>4 seconds ago</td>
                <td><i className="fa-solid fa-ellipsis"></i></td>
              </tr>
              <tr>
                <th scope="row">Ac sit etiam velit, consequat est augue commodo non.</th>
                <td><button className="status-btn in-close">Close</button></td>
                <td>4 seconds ago</td>
                <td><i className="fa-solid fa-ellipsis"></i></td>
              </tr>



            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
