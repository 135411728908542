import React from 'react'
import { Link } from 'react-router-dom';
export default function technical() {

    const blockfilter = () => {
        document.querySelector(".filtercontainer").classList.add("block");
    }
    const removefilter = () => {
        document.querySelector(".filtercontainer").classList.remove("block");
    }
    const linkredirect = (e) => {

        window.location.href = '/property-painting-process';
    }
    return (
        <>
            <div className="dashboard propertyDetailspage facility-manager Property-technical">

                <div className="cards">
                    <div className="card" style={{ width: "24rem" }}>

                        <div className="row nv">
                            <div className="dis">Total Budget</div>
                            <div className="number">$1000</div>
                        </div>
                        <div className="row hd">
                            <div className="icon"><img src={"/lbs/images/bugettotal.png"} alt="" /></div>

                        </div>
                    </div>
                    <div className="card" style={{ width: "24rem" }}>

                        <div className="row nv">
                            <div className="dis">Used Budget</div>
                            <div className="number">$300</div>
                        </div>
                        <div className="row hd">
                            <div className="icon"><img src={"/lbs/images/userbeget.png"} alt="" /></div>

                        </div>
                    </div>
                    <div className="card" style={{ width: "24rem" }}>

                        <div className="row nv">
                            <div className="dis">Remaining Budget</div>
                            <div className="number">$700</div>
                        </div>
                        <div className="row hd">
                            <div className="icon"><img src={"/lbs/images/remaing.png"} alt="" /></div>

                        </div>
                    </div>


                </div>
                <div className="titleanddetail">
                    <div className="pageslug">
                        <a>Approval Processes</a>
                    </div>
                </div>
                <div className="col-md-12 addpropertyForm row">
                    <div className="col-md-6 d-flex justify-content-start">
                        <Link to="/propertys"><button type="submit" className="btn2 btn-primary">Technical Process</button></Link>
                        <Link to="/property-contract"><button type="submit" className="btn3 btn-primary">Contract Process</button></Link>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end pdrv">
                        <div className="titleanddetail">
                          
                           
                                <Link to="/property-manager-add" className="btn detail">Create New Process</Link>
                                <a href="#" className="detail filter" onClick={blockfilter}><img src={"/lbs/images/filter.png"} /></a>
                        </div>
                    </div>
                </div>
                <div className='filtercontainer'>
                    <div className='filterform'>
                        <div className="titleanddis">

                            <div className="dis">
                                <div className="addpropertyForm">

                                    <div className="title">Filter <div className='close' onClick={removefilter}><img src={"/lbs/images/close.png"} /></div></div>

                                    <form className="row g-3">

                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">Property No</label>
                                            <select id="inputState" className="form-select">
                                                <option selected="">Select Option</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">Property Type</label>
                                            <select id="inputState" className="form-select">
                                                <option selected="">Select Option</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">Process</label>
                                            <select id="inputState" className="form-select">
                                                <option selected="">Select Option</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">Assignee</label>
                                            <select id="inputState" className="form-select">
                                                <option selected="">Select Option</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">Cost</label>
                                            <select id="inputState" className="form-select">
                                                <option selected="">Select Option</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">Budget</label>
                                            <select id="inputState" className="form-select">
                                                <option selected="">Select Option</option>
                                                <option>...</option>
                                            </select>
                                        </div>


                                        <div className="col-md-12 d-flex justify-content-end">
                                            <button type="submit" className="btn2 btn-primary">Reset</button>
                                            <button type="submit" className="btn btn-primary">Apply</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tenantsTable">
                    <table className="table teantsTableinpropertydetails propertydetails2">
                        <thead>
                            <tr>
                                <th scope="col">Property No</th>
                                <th scope="col">Property Type</th>
                                <th scope="col">Processes</th>
                                <th scope="col">Assigned To</th>
                                <th scope="col">Cost</th>
                                <th scope="col">Budget</th>
                                <th scope="col">Assigned To</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            
                            <tr onClick={linkredirect}>
                                <td>01</td>
                                <td>Home</td>
                                <td>Painting</td>
                                <td colspan="1"><button className="status-btn in-blue">Facility Manager</button></td>
                                <td>$250</td>
                                <td>$150</td>
                                <td colspan="1"><button className="status-btn in-open">Property Manager</button></td>
                                <td>
                                    <i className="fa-solid fa-ellipsis">
                                        <div className='actionpopup'>
                                            <ul>
                                                <li><a href='#'><img src={"/lbs/images/actionview.png"} /><span>View</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actionedit.png"} /><span>Edit</span></a></li>
                                                <li><a href='#'><img src={"/lbs/images/actiondelete.png"} /><span>Delete</span></a></li>
                                            </ul>
                                        </div>
                                    </i>
                                </td>
                            </tr>
                            



                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
