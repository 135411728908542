import React from 'react'

export default function notification() {
    return (
        <>
            <div className="dashboard notification">
                <div className="titleanddetail titleanddis">
                    <div className="pageslug title">
                        <a>Notifications</a>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='notification-list'>
                        <ul>
                            <li>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Announcing the winners of the The only book awards  decided by you, the readers. Check out the champions and runners-up in all 21 categories now!
                                <div className='notfi-time'>just now</div>
                                </div>
                                
                            </li>
                            <li className='unread'>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Last chance to vote in The 2018 Falcon Choice Awards  See what made it to the Final Round and help your favorites take home the win. Voting closes on November 26
                                <div className='notfi-time'>15m</div>
                                </div>
                                
                            </li>
                            <li>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Announcing the winners of the The only book awards  decided by you, the readers. Check out the champions and runners-up in all 21 categories now!
                                <div className='notfi-time'>just now</div>
                                </div>
                                
                            </li>
                            <li className='unread'>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Last chance to vote in The 2018 Falcon Choice Awards  See what made it to the Final Round and help your favorites take home the win. Voting closes on November 26
                                <div className='notfi-time'>15m</div>
                                </div>
                                
                            </li>
                            <li>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Announcing the winners of the The only book awards  decided by you, the readers. Check out the champions and runners-up in all 21 categories now!
                                <div className='notfi-time'>just now</div>
                                </div>
                                
                            </li>
                            <li className='unread'>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Last chance to vote in The 2018 Falcon Choice Awards  See what made it to the Final Round and help your favorites take home the win. Voting closes on November 26
                                <div className='notfi-time'>15m</div>
                                </div>
                                
                            </li>
                            <li>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Announcing the winners of the The only book awards  decided by you, the readers. Check out the champions and runners-up in all 21 categories now!
                                <div className='notfi-time'>just now</div>
                                </div>
                                
                            </li>
                            <li className='unread'>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Last chance to vote in The 2018 Falcon Choice Awards  See what made it to the Final Round and help your favorites take home the win. Voting closes on November 26
                                <div className='notfi-time'>15m</div>
                                </div>
                                
                            </li>
                            <li>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Announcing the winners of the The only book awards  decided by you, the readers. Check out the champions and runners-up in all 21 categories now!
                                <div className='notfi-time'>just now</div>
                                </div>
                                
                            </li>
                            <li className='unread'>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Last chance to vote in The 2018 Falcon Choice Awards  See what made it to the Final Round and help your favorites take home the win. Voting closes on November 26
                                <div className='notfi-time'>15m</div>
                                </div>
                                
                            </li>
                            <li>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Announcing the winners of the The only book awards  decided by you, the readers. Check out the champions and runners-up in all 21 categories now!
                                <div className='notfi-time'>just now</div>
                                </div>
                                
                            </li>
                            <li className='unread'>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Last chance to vote in The 2018 Falcon Choice Awards  See what made it to the Final Round and help your favorites take home the win. Voting closes on November 26
                                <div className='notfi-time'>15m</div>
                                </div>
                                
                            </li>
                            <li>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Announcing the winners of the The only book awards  decided by you, the readers. Check out the champions and runners-up in all 21 categories now!
                                <div className='notfi-time'>just now</div>
                                </div>
                                
                            </li>
                            <li className='unread'>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Last chance to vote in The 2018 Falcon Choice Awards  See what made it to the Final Round and help your favorites take home the win. Voting closes on November 26
                                <div className='notfi-time'>15m</div>
                                </div>
                                
                            </li>
                            <li>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Announcing the winners of the The only book awards  decided by you, the readers. Check out the champions and runners-up in all 21 categories now!
                                <div className='notfi-time'>just now</div>
                                </div>
                                
                            </li>
                            <li className='unread'>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Last chance to vote in The 2018 Falcon Choice Awards  See what made it to the Final Round and help your favorites take home the win. Voting closes on November 26
                                <div className='notfi-time'>15m</div>
                                </div>
                                
                            </li>
                            <li>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Announcing the winners of the The only book awards  decided by you, the readers. Check out the champions and runners-up in all 21 categories now!
                                <div className='notfi-time'>just now</div>
                                </div>
                                
                            </li>
                            <li className='unread'>
                                <div className='profile'>
                                <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                </div>
                                <div className='notfi-title'>Last chance to vote in The 2018 Falcon Choice Awards  See what made it to the Final Round and help your favorites take home the win. Voting closes on November 26
                                <div className='notfi-time'>15m</div>
                                </div>
                                
                            </li>
                            </ul>

                    </div>
                </div>
            </div>
        </>
    )
}
