import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function propertylist() {
  return (
    <>
     <div className="dashboard">

<div className="titleanddetail">
  <span className="title">Propertys</span>
  <Link to="/addproperty" className="btn detail">Add New Property</Link>
</div>
<div className="propertyList">
  <Link to="/property-details"  className="property">
    <div className="thumbanil">
      <img src={"./lbs/images/Property1.png"} alt=""/>
    </div>
    <div className="detail">
      <ul>
        <li><label for="Country">Country:</label>
          <div className="detail">United States of America</div>
        </li>
        <li><label for="City">City:</label>
          <div className="detail">New York</div>
        </li>
        <li><label for="Street">Street:</label>
          <div className="detail">Street #1</div>
        </li>
        <li><label for="Address:">Address:</label>
          <div className="detail">4517 Washington Ave. Manchester, Kentucky 39495</div>
        </li>
        <li><label for="Building">Building:</label>
          <div className="detail">Building #1</div>
        </li>
        <li><label for="Squaremeters">Squaremeters:</label>
          <div className="detail">272sqm</div>
        </li>
        <li><label for="Floor">Floor:</label>
          <div className="detail">Floor #1</div>
        </li>
        <li><label for="Number of Tenants:">Number of Tenants:</label>
          <div className="detail">8</div>
        </li>
        <li><label for="Contact Security Person of Property">Contact Security Person of Property:</label>
          <div className="detail">(406) 555-0120</div>
        </li>
      </ul>
    </div>
  </Link>
  <Link to="/property-details"  className="property">
    <div className="thumbanil">
      <img src={"./lbs/images/Property2.png"} alt=""/>
    </div>
    <div className="detail">
      <ul>
        <li><label for="Country">Country:</label>
          <div className="detail">United States of America</div>
        </li>
        <li><label for="City">City:</label>
          <div className="detail">New York</div>
        </li>
        <li><label for="Street">Street:</label>
          <div className="detail">Street #1</div>
        </li>
        <li><label for="Address:">Address:</label>
          <div className="detail">4517 Washington Ave. Manchester, Kentucky 39495</div>
        </li>
        <li><label for="Building">Building:</label>
          <div className="detail">Building #1</div>
        </li>
        <li><label for="Squaremeters">Squaremeters:</label>
          <div className="detail">272sqm</div>
        </li>
        <li><label for="Floor">Floor:</label>
          <div className="detail">Floor #1</div>
        </li>
        <li><label for="Number of Tenants:">Number of Tenants:</label>
          <div className="detail">8</div>
        </li>
        <li><label for="Contact Security Person of Property">Contact Security Person of Property:</label>
          <div className="detail">(406) 555-0120</div>
        </li>
      </ul>
    </div>
  </Link>
  <Link to="/property-details"  className="property">
    <div className="thumbanil">
      <img src={"./lbs/images/Property3.png"} alt=""/>
    </div>
    <div className="detail">
      <ul>
        <li><label for="Country">Country:</label>
          <div className="detail">United States of America</div>
        </li>
        <li><label for="City">City:</label>
          <div className="detail">New York</div>
        </li>
        <li><label for="Street">Street:</label>
          <div className="detail">Street #1</div>
        </li>
        <li><label for="Address:">Address:</label>
          <div className="detail">4517 Washington Ave. Manchester, Kentucky 39495</div>
        </li>
        <li><label for="Building">Building:</label>
          <div className="detail">Building #1</div>
        </li>
        <li><label for="Squaremeters">Squaremeters:</label>
          <div className="detail">272sqm</div>
        </li>
        <li><label for="Floor">Floor:</label>
          <div className="detail">Floor #1</div>
        </li>
        <li><label for="Number of Tenants:">Number of Tenants:</label>
          <div className="detail">8</div>
        </li>
        <li><label for="Contact Security Person of Property">Contact Security Person of Property:</label>
          <div className="detail">(406) 555-0120</div>
        </li>
      </ul>
    </div>
  </Link>
  <Link to="/property-details"  className="property">
    <div className="thumbanil">
      <img src={"/lbs/images/Property1.png"} alt=""/>
    </div>
    <div className="detail">
      <ul>
        <li><label for="Country">Country:</label>
          <div className="detail">United States of America</div>
        </li>
        <li><label for="City">City:</label>
          <div className="detail">New York</div>
        </li>
        <li><label for="Street">Street:</label>
          <div className="detail">Street #1</div>
        </li>
        <li><label for="Address:">Address:</label>
          <div className="detail">4517 Washington Ave. Manchester, Kentucky 39495</div>
        </li>
        <li><label for="Building">Building:</label>
          <div className="detail">Building #1</div>
        </li>
        <li><label for="Squaremeters">Squaremeters:</label>
          <div className="detail">272sqm</div>
        </li>
        <li><label for="Floor">Floor:</label>
          <div className="detail">Floor #1</div>
        </li>
        <li><label for="Number of Tenants:">Number of Tenants:</label>
          <div className="detail">8</div>
        </li>
        <li><label for="Contact Security Person of Property">Contact Security Person of Property:</label>
          <div className="detail">(406) 555-0120</div>
        </li>
      </ul>
    </div>
  </Link>
</div>
</div>
    
    </>
  )
}
