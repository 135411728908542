import React from 'react'
import { Link } from 'react-router-dom'
export default function overview() {
    return (
        <>
            <div className="dashboard propertyDetailspage approvalprocess">

                <div className="titleanddetail">
                    <div className="pageslug">
                        <li><Link to="/facility-manager">Approval Process </Link></li>
                        <li><Link to="#">Painting Process</Link></li>
                    </div>
                  
                </div>
                <div className='propertyList '>

                    <Link to="#" className="property">
                        <div className="thumbanil">
                            <img src={"./lbs/images/Property1.png"} alt="" />
                        </div>
                        <div className="detail">
                            <ul>
                                <li><label for="Country">Country:</label>
                                    <div className="detail">United States of America</div>
                                </li>
                                <li><label for="City">City:</label>
                                    <div className="detail">New York</div>
                                </li>
                                <li><label for="Street">Street:</label>
                                    <div className="detail">Street #1</div>
                                </li>
                                <li><label for="Address:">Address:</label>
                                    <div className="detail">4517 Washington Ave. Manchester, Kentucky 39495</div>
                                </li>
                                <li><label for="Building">Building:</label>
                                    <div className="detail">Building #1</div>
                                </li>
                                <li><label for="Squaremeters">Squaremeters:</label>
                                    <div className="detail">272sqm</div>
                                </li>
                                <li><label for="Floor">Floor:</label>
                                    <div className="detail">Floor #1</div>
                                </li>
                                <li><label for="Number of Tenants:">Number of Tenants:</label>
                                    <div className="detail">8</div>
                                </li>
                                <li><label for="Contact Security Person of Property">Contact Security Person of Property:</label>
                                    <div className="detail">(406) 555-0120</div>
                                </li>
                            </ul>
                        </div>
                    </Link>

                </div>
                <div className="row">
                    <div className="col-md-12">

                        <div className="titleanddis contactDetailsbyposition">
                            <div className="title normal">Process Overview</div>
                            <div className="dis">
                                <div className="detail">
                                    <ul>
                                        <li><label for="Country">Property No:</label>
                                            <div className="detail">01</div>
                                            
                                        </li>
                                        <li><label for="Country">Property Type:</label>
                                            <div className="detail">Home</div>
                                            
                                        </li>
                                        <li><label for="Country">Process Type:</label>
                                            <div className="detail">Painting Process</div>
                                            
                                        </li>
                                        <li><label for="Country">Assigned To:</label>
                                            <div className="detail">Property Manager</div>
                                            
                                        </li>
                                        <li><label for="Country">Cost:</label>
                                            <div className="detail">$250</div>
                                            
                                        </li>
                                        <li><label for="Country">Budget:</label>
                                            <div className="detail">$150</div>
                                            
                                        </li>
                                     

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="titleanddis contactDetailsbyposition">
                            <div className="title normal">Description:</div>
                            <div className="dis">
                                <div className='detail'>
                                <ul>
                           
                                    Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                Senectus non et penatibus magna sed ornare adipiscing. Sed arcu, adipiscing facilisis et magna vitae porttitor felis. Sit tellus luctus
                                quam faucibus id diam nunc, at. Nec nisl, quisque aliquet phasellus condimentum vehicula a tellus blandit. Turpis ornare in bibendum
                                et et, lobortis. Morbi commodo pretium dignissim pharetra, aenean et montes, non. Justo adipiscing vitae scelerisque consectetur
                                consequat magna nibh. Eros, dui, fermentum elit sem lorem facilisis tortor ut. Morbi mi pellentesque feugiat vehicula ultrices felis lectus.
                                Ut quis enim viverra vulputate. Odio ut malesuada posuere ut cursus velit commodo. Iaculis blandit dictumst vitae suspendisse auctor.
                                Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                Senectus non et penatibus magna sed ornare adipiscing. Sed arcu, adipiscing facilisis et magna vitae porttitor felis. Sit tellus luctus
                                quam faucibus id diam nunc, at. Morbi mi pellentesque feugiat vehicula ultrices felis lectus.
                                Ut quis enim viverra vulputate. Odio ut malesuada posuere ut cursus velit commodo.
                             
                                </ul>
                                </div>
                            </div>
                        </div>
                        <div className="titleanddis">
                            <div className="title">Attachments</div>
                            <div className="dis">
                                <div className="detail">
                                    <ul className='attachments'>
                                        <li><img src={"/lbs/images/att.png"} /><label for="Country">Property document.pdf(12kb)</label> <img src={"/lbs/images/download.png"} />
                                        </li>
                                        <li><img src={"/lbs/images/att.png"} /><label for="Country">Property document.pdf(12kb)</label> <img src={"/lbs/images/download.png"} />
                                        </li>
                                        
                                        

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>




            </div>
        </>
    )
}
