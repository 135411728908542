import React from 'react'
import { Link } from 'react-router-dom'

export default function setting() {
  return (
    <>
     <div className='dashboard'>
                <div className="titleanddetail">
                    <div className="pageslug">
                        <a href="#">Account</a>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='menus'>
                        <ul>
                            <li><Link to='/genarel-setting' >General</Link></li>
                            <li><a href='#' className='active'>Setting</a></li>
                            <li><Link to='/security-setting'>Security</Link></li>

                        </ul>
                    </div>
                </div>

                <div className='col-md-12 mt-5'>
                    <div className='settingflex'>
                        <ul>
                            <li>
                                <div className='left'>
                                    <div className='img'>
                                        <img src={'../lbs/images/notification-2.png'}/>
                                    </div>
                                    <div className='detail'>
                                    <div className='title'>Notifications </div>
                                    <div className='dis'>Object: Gartenstraße 1</div>
                                    </div>
                                   
                                </div>
                                <div className='right'> <button className='togglebar'><i className='ber'></i></button></div>
                            </li>
                        </ul>
                    </div>
                
                </div>
             
            </div>
    </>
  )
}
