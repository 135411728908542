import React from 'react'
import { Link } from 'react-router-dom'

export default function performance() {
    const linkredirect = (e) => {

        window.location.href = "/performance";
    }
    return (
        <>
            <div className="dashboard performance">

                <div className="mx-4 titleanddetail performance-header">
                    <div className='col-md-7 d-flex'>
                        <div className="pageslug">
                            <a>Lakewood Property - Status and Performance Overview
                            </a>
                        </div>
                    </div>
                    <div className='col-md-5 d-flex align-items-center'>
                        <div className='col-md-8'>
                            <a className='btn1'>Overview</a>
                            <Link to="/performance-detail" className='btn2'>Detail</Link>
                        </div>
                        <div className='col-md-3 justify-content-end'>
                            <select id="inputState" className="form-select">
                                <option selected="">ENG</option>

                            </select>
                            <a className='btn3 mt-2'>Export</a>
                        </div>
                    </div>

                </div>
                <div className='propertyList m-4'>

                    <Link to="/property-details" className="property">
                        <div className="thumbanil">
                            <img src={"./lbs/images/bilder_2_0.jpeg"} alt="" />
                        </div>
                        <div className="detail">
                            <ul>
                                <li><label for="Country">Country:</label>
                                    <div className="detail">Germany</div>
                                </li>
                                <li><label for="City">City:</label>
                                    <div className="detail">Stuttgart</div>
                                </li>
                                <li><label for="Street">Street:</label>
                                    <div className="detail">Kriegsbergstraße 11</div>
                                </li>
                                <li><label for="Address:">Address:</label>
                                    <div className="detail">Kriegsbergstraße 11, 70174 Stuttgart, Germany</div>
                                </li>
                                <li><label for="Building">Building parts:</label>
                                    <div className="detail">1</div>
                                </li>
                                <li><label for="Squaremeters">Total area:</label>
                                    <div className="detail">16.000 sqm</div>
                                </li>
                                <li><label for="Floor">Rental space:</label>
                                    <div className="detail">10.000 sqm</div>
                                </li>
                                <li><label for="Number of Tenants:">Number of Tenants:</label>
                                    <div className="detail">4</div>
                                </li>
                                <li><label for="Number of Tenants:">Current vacancy: </label>
                                    <div className="detail">500 sqm</div>
                                </li>
                                <li><label for="Number of Tenants:">Number of floors:</label>
                                    <div className="detail">11</div>
                                </li>


                            </ul>
                        </div>
                    </Link>

                </div>

                <div className='titleandsub m-4'>
                    <div className="title">Current YTD overview of the most important key figures of the property</div>
                    <div className='sub'>Daily update</div>
                </div>
                <div className='col-md-12'>
                <div className='row mt-2 perforemance-card short'>
                    <div className="cards">
                        <div className="card">
                            <div className="row hd">
                                <div className="icon"><img src={"/lbs/images/per-ic-4.png"} alt="" /></div>
                                <div className="name">56 <div className="dis">Interactions</div></div>

                            </div>
                        </div>
                        <div className="card">
                            <div className="row hd">
                                <div className="icon"><img src={"/lbs/images/clock.png"} alt="" /></div>
                                <div className="name">126 min <div className="dis">Reaction time
                                </div></div>

                            </div>
                        </div>
                        <div className="card" >
                            <div className="row hd">
                                <div className="icon"><img src={"/lbs/images/per-ic-1.png"} alt="" /></div>
                                <div className="name">38,3 % <div className="dis">Current  Performance</div></div>

                            </div>
                        </div>
                        <div className="card" >
                            <div className="row hd">
                                <div className="icon"><img src={"/lbs/images/per-c-2.png"} alt="" /></div>
                                <div className="name">65 % <div className="dis">Property effort
                                </div></div>

                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div className='col-md-12 row m-3 gx-5'>

                    <div className='col-md-6'>

                        <div className='row mt-4 perforemance-card'>
                            <div className='titleandsub m-2 mb-4 '>
                                <div className="title">Current tasks Approval Process
                                </div>
                                <div className='sub'>Presentation of the tasks still to be completed
                                </div>
                            </div>
                            <div className="cards">
                                <div className="card" style={{ width: "18rem" }}>
                                    <div className="row hd">

                                        <div className="name">45 <div className="dis">Schedule fits</div></div>

                                    </div>
                                </div>
                                <div className="card" style={{ width: "18rem" }}>
                                    <div className="row hd">

                                        <div className="name">45 <div className="dis">Attention</div></div>

                                    </div>
                                </div>
                                <div className="card" style={{ width: "18rem" }}>
                                    <div className="row hd">

                                        <div className="name">20 <div className="dis">Delayed</div></div>

                                    </div>
                                </div>

                            </div>
                            <div className='graph'>
                                <div className='grap-curcle'>
                                    <img src={"/lbs/images/chart2.png"} />
                                </div>
                                <div className='grap-progress'>
                                    <ul>
                                        <li>
                                            <div className='name'>Delayed (20%) </div>
                                            <div className='progress-grapbar'>
                                                <div class="progress">
                                                    <div class="progress-bar delayed" role="progressbar" style={{ width: '20%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className='persent'>20 </div>
                                        </li>
                                        <li>
                                            <div className='name'>Attention (35%)</div>
                                            <div className='progress-grapbar'>
                                                <div class="progress">
                                                    <div class="progress-bar attention" role="progressbar" style={{ width: '35%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className='persent'>35 </div>
                                        </li>
                                        <li>
                                            <div className='name'>Schedule fits (45%)</div>
                                            <div className='progress-grapbar'>
                                                <div class="progress">
                                                    <div class="progress-bar schedule" role="progressbar" style={{ width: '45%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className='persent'>45</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>

                        <div className='row mt-4 perforemance-card'>
                            <div className='titleandsub m-2 mb-4 '>
                                <div className="title">Current task Jour Fixe
                                </div>
                                <div className='sub'>Presentation of the tasks still to be completed
                                </div>
                            </div>
                            <div className="cards">
                                <div className="card" style={{ width: "18rem" }}>
                                    <div className="row hd">

                                        <div className="name">45 <div className="dis">Schedule fits</div></div>

                                    </div>
                                </div>
                                <div className="card" style={{ width: "18rem" }}>
                                    <div className="row hd">

                                        <div className="name">45 <div className="dis">Attention</div></div>

                                    </div>
                                </div>
                                <div className="card" style={{ width: "18rem" }}>
                                    <div className="row hd">

                                        <div className="name">20 <div className="dis">Delayed</div></div>

                                    </div>
                                </div>

                            </div>
                            <div className='graph'>
                                <div className='grap-curcle'>
                                    <img src={"/lbs/images/chart.png"} />
                                </div>
                                <div className='grap-progress'>
                                    <ul>
                                        <li>
                                            <div className='name'>Delayed (20%) </div>
                                            <div className='progress-grapbar'>
                                                <div class="progress">
                                                    <div class="progress-bar delayed" role="progressbar" style={{ width: '20%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className='persent'>20 </div>
                                        </li>
                                        <li>
                                            <div className='name'>Attention (45%)</div>
                                            <div className='progress-grapbar'>
                                                <div class="progress">
                                                    <div class="progress-bar attention" role="progressbar" style={{ width: '45%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className='persent'>45 </div>
                                        </li>
                                        <li>
                                            <div className='name'>Schedule fits (35%)</div>
                                            <div className='progress-grapbar'>
                                                <div class="progress">
                                                    <div class="progress-bar schedule" role="progressbar" style={{ width: '35%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className='persent'>35</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='col-md-12 row m-3 gx-5'>

                    <div className='col-md-6'>

                        <div className='row mt-4 perforemance-card'>
                            <div className='titleandsub m-2 mb-4 '>
                                <div className="title">Current Overview Budget
                                </div>
                                <div className='sub'>Evaluation based on the budget function and the current approval processes.
                                </div>
                                <div className='sub mt-5 mb-2'>Surplus
                                </div>
                                <div className='chart-container'>
                                <div className='chart-bar'>
                                    <div className='y'>
                                        <ul>
                                            <li><div className='title'>150k</div></li>
                                            <li><div className='title'>100k</div></li>
                                            <li><div className='title'>50k</div></li>
                                            <li><div className='title'></div></li>
                                        </ul>
                                    </div>
                                    <div className='x'>
                                        <ul>
                                            <li style={{ '--height': '20px' }}><div className='title'>1</div></li>
                                            <li style={{ '--height': '120px' }}><div className='title'>2</div></li>
                                            <li style={{ '--height': '70px' }}><div className='title'>3</div></li>
                                            <li style={{ '--height': '80px' }}><div className='title'>4</div></li>
                                            <li style={{ '--height': '20px' }}><div className='title'>5</div></li>
                                            <li style={{ '--height': '30px' }}><div className='title'>6</div></li>
                                            <li style={{ '--height': '120px' }}><div className='title'>7</div></li>
                                            <li style={{ '--height': '60px' }}><div className='title'>8</div></li>
                                            <li style={{ '--height': '40px' }}><div className='title'>9</div></li>
                                            <li style={{ '--height': '30px' }}><div className='title'>10</div></li>
                                            <li style={{ '--height': '150px' }}><div className='title'>11</div></li>
                                            <li style={{ '--height': '100px' }}><div className='title'>12</div></li>

                                        </ul>
                                        <div className='sub mt-2 mb-2 d-flex justify-content-center'>Month
                                        </div>

                                    </div>

                                </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-6'>

                        <div className='row mt-4 perforemance-card'>
                            <div className='titleandsub m-2 mb-4 '>
                                <div className="title">Current task tenant tickets
                                </div>
                                <div className='sub'>Presentation of the tasks still to be completed
                                </div>
                            </div>
                            <div className="cards">
                                <div className="card" style={{ width: "18rem" }}>
                                    <div className="row hd">

                                        <div className="name">45 <div className="dis">Schedule fits</div></div>

                                    </div>
                                </div>
                                <div className="card" style={{ width: "18rem" }}>
                                    <div className="row hd">

                                        <div className="name">45 <div className="dis">Attention</div></div>

                                    </div>
                                </div>
                                <div className="card" style={{ width: "18rem" }}>
                                    <div className="row hd">

                                        <div className="name">20 <div className="dis">Delayed</div></div>

                                    </div>
                                </div>

                            </div>
                            <div className='graph'>
                                <div className='grap-curcle'>
                                    <img src={"/lbs/images/chart.png"} />
                                </div>
                                <div className='grap-progress'>
                                    <ul>
                                        <li>
                                            <div className='name'>Delayed (20%) </div>
                                            <div className='progress-grapbar'>
                                                <div class="progress">
                                                    <div class="progress-bar delayed" role="progressbar" style={{ width: '20%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className='persent'>20 </div>
                                        </li>
                                        <li>
                                            <div className='name'>Attention (45%)</div>
                                            <div className='progress-grapbar'>
                                                <div class="progress">
                                                    <div class="progress-bar attention" role="progressbar" style={{ width: '45%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className='persent'>45 </div>
                                        </li>
                                        <li>
                                            <div className='name'>Schedule fits (35%)</div>
                                            <div className='progress-grapbar'>
                                                <div class="progress">
                                                    <div class="progress-bar schedule" role="progressbar" style={{ width: '35%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                            <div className='persent'>35</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='col-md-12 row m-4'>
                    <div className="addpropertyForm p-4">
                        <div class="col-12"><label for="inputAddress" class="form-label mb-2">Personal notes</label><textarea name="" id="" cols="30" rows="10" placeholder="" class="form-control" spellcheck="false"></textarea></div>
                    </div>
                </div>

            </div>
        </>
    )
}
