import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function setnewpass() {
  return (
    <>
        <div className="page">
        <div className="loginpage">
            <div className="leftsection">
               <div className="logo">
                    <img src={"/lbs/images/vlrlogo.png"} alt=""/>
               </div>
               <div className="page-sub-heading">
                   <p>Welcome to JamesHelps! After logging in, I will be happy to help.</p>
               </div>
            </div>
            <div className="rightsection">
                    <div className="formcontainer">
                        <div className="pagetitle">Set New Password</div>

                        <form action="?" method="post">
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">Email code</label>
                                <input type="text" className="form-control" id="exampleFormControlInput1"/>
                              </div>
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">New Password</label>
                                <input type="password" className="form-control" id="exampleFormControlInput1"/>
                              </div>
                              
                              <button type="submit" className="btn btn-primary">Save new password</button>
                        </form>
                      
                    </div>
            </div>
        </div>
    </div>
    </>
  )
}
