import React from 'react'

export default function chat() {
    return (
        <>
            <div className="dashboard facility-chat">
                <div className='col-md-12 row'>
                    <div className='col-md-4 scroll'>
                        <div className='serarchinchatandfilter'>

                            <div className='searchform'>
                                <form method='post'>
                                    <div className='searchinput'>
                                        <img src={"/lbs/images/bx-search-alt.png"} className="profileImage" alt="" />
                                        <input type="text" placeholder='Search' />
                                        <img src={"/lbs/images/downarray.png"} className="profileImage" alt="" />
                                    </div>
                                </form>
                            </div>
                            <div className='filter'><img src={"/lbs/images/filter.png"} />
                                <ul>
                                    <li>Property 1</li>
                                    <li>Property 2</li>
                                    <li>Property 3</li>
                                    <li>Property 4</li>
                                    <li>Property 5</li>
                                </ul>
                            </div>
                        </div>

                        <div className='channels'>
                            <div className="titleanddetail">
                                <div className="pageslug">
                                    <a>Channels</a>
                                </div>
                                
                            </div>
                            <ul>
                                <li><a href='#'><span>#</span>Contract</a></li>
                                <li><a href='#'><span>#</span>Wood Work</a></li>
                                <li><a href='#'><span>#</span>Construction</a></li>
                                <li><a href='#'><span>#</span>Painting</a></li>
                            </ul>
                        </div>
                        <div className='chatboxlist'>
                            <div className="titleanddetail">
                                <div className="pageslug">
                                    <a>Chat</a>
                                </div>
                            </div>
                            <ul>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href='#'>
                                        <div className='profilepic'>
                                            <img src={"/lbs/images/profile.png"} className="profileImage" alt="" />
                                        </div>
                                        <div className='details'>
                                            <div className='name'>Nell Bell</div>
                                            <div className='lastmsg'>Atoms consists of ules</div>
                                        </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className='col-md-8'>
                        <div className="contact-query-box">

                            <div className="col-md-12">
                                <div className="imageContainer">
                                    <div className='left'>
                                    <img src={"/lbs/images/profile.png"} alt="" />
                                    <div className="nameandtitle">
                                        <a className="subject-header">Jenny Perich</a>
                                        
                                    </div>
                                    </div>
                                    <ul className='right'>
                                        <li><img src={"/lbs/images/bx-star.png"} /></li>
                                        <li><img src={"/lbs/images/bx-dots-vertical-rounded.png"} /></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="chatlist">
                                    <ul>
                                        <li>
                                            <div className="imageandmsg">
                                                <div className="imageanddate">
                                                    <img src={"lbs/images/profile.png"} alt="" />
                                                   
                                                    
                                                </div>
                                                <div className="message">
                                                    Cras sit amet nibh libero, in gravida nulla. Nulla vel met scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis.
                                                     <span>5 min ago</span>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="me">
                                            <div className="imageandmsg">
                                                <div className="imageanddate">
                                                    <img src={"lbs/images/profile.png"} alt="" />
                                                   
                                                    
                                                </div>
                                                <div className="message">
                                                    Cras sit amet nibh libero, in gravida nulla. Nulla vel met scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis.
                                                     <span>5 min ago</span>
                                                </div>
                                            </div>
                                        </li>

                                    </ul>
                                </div>
                                <div className="sendmsgbox">
                                    <form action="" method="post">
                                        <input type="text" name="" id="" placeholder="Type a message here…" />
                                        <ul>
                                            <li><img src={"/lbs/images/img-att.png"} alt="" /></li>
                                            <li><img src={"/lbs/images/bx-att.png"} alt="" /></li>
                                            <li><img src={"/lbs/images/bx-send.png"} alt="" /></li>

                                        </ul>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
