import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function propertydetails() {
    return (
        <>
            <div className="dashboard propertyDetailspage">

                <div className="titleanddetail">
                    <div className="pageslug">
                        <li><Link to="#">All Properties</Link></li>
                        <li><Link to="#">Property Detail</Link></li>
                    </div>
                    <Link to="#" className="btn detail">Update</Link>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div className="titleanddis">
                            <div className="title">Property Details: <span><Link to="/property-details-2">View All
                                Tenants</Link></span></div>
                            <div className="dis">
                                <div className="detail">
                                    <ul>
                                        {/* <li><label for="Country">Country:</label>
                                            <div className="detail">United States of America</div>
                                        </li>
                                        <li><label for="City">City:</label>
                                            <div className="detail">New York</div>
                                        </li>
                                        <li><label for="Street">Street:</label>
                                            <div className="detail">Street #1</div>
                                        </li>
                                        <li><label for="Address:">Address:</label>
                                            <div className="detail">4517 Washington Ave. Manchester, Kentucky 39495</div>
                                        </li>
                                        <li><label for="Building">Building:</label>
                                            <div className="detail">Building #1</div>
                                        </li>
                                        <li><label for="Squaremeters">Squaremeters:</label>
                                            <div className="detail">272sqm</div>
                                        </li>
                                        <li><label for="Floor">Floor:</label>
                                            <div className="detail">Floor #1</div>
                                        </li>
                                        <li><label for="Number of Tenants:">Number of Tenants:</label>
                                            <div className="detail">8</div>
                                        </li>
                                        <li><label for="Contact Security Person of Property">Contact Security Person of
                                            Property:</label>
                                            <div className="detail">(406) 555-0120</div>
                                        </li> */}

                                        <li><label for="Country">Country:</label>
                                            <div className="detail">Germany</div>
                                        </li>
                                        <li><label for="City">City:</label>
                                            <div className="detail">Stuttgart</div>
                                        </li>
                                        <li><label for="Street">Street:</label>
                                            <div className="detail">Kriegsbergstraße 11</div>
                                        </li>
                                        <li><label for="Address:">Address:</label>
                                            <div className="detail">Kriegsbergstraße 11, 70174 Stuttgart, Germany</div>
                                        </li>
                                        <li><label for="Building">Building parts:</label>
                                            <div className="detail">1</div>
                                        </li>
                                        <li><label for="Squaremeters">Total area:</label>
                                            <div className="detail">16.000 sqm</div>
                                        </li>
                                        <li><label for="Floor">Rental space:</label>
                                            <div className="detail">10.000 sqm</div>
                                        </li>
                                        <li><label for="Number of Tenants:">Number of Tenants:</label>
                                            <div className="detail">4</div>
                                        </li>
                                        <li><label for="Number of Tenants:">Current vacancy: </label>
                                            <div className="detail">500 sqm</div>
                                        </li>
                                        <li><label for="Number of Tenants:">Number of floors:</label>
                                            <div className="detail">11</div>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="titleanddis">
                            <div className="title">Contract Details:</div>
                            <div className="dis">
                                <div className="detail">
                                    <ul>
                                        <li><label for="Country">Tenant:</label>
                                            <div className="detail">Mia Smith</div>
                                        </li>
                                        <li><label for="City">Landlord:</label>
                                            <div className="detail">Real Estate Company</div>
                                        </li>
                                        <li><label for="Street">Property:</label>
                                            <div className="detail">House</div>
                                        </li>
                                        <li><label for="Address:">Payments:</label>
                                            <div className="detail">3.000 € per month </div>
                                        </li>
                                        <li><label for="Building">Payment system:</label>
                                            <div className="detail">Bank Transfer</div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="titleanddis contactDetailsbyposition">
                            <div className="title">Contact Details:</div>
                            <div className="dis">
                                <div className="detail">
                                    <ul>
                                        <li><label for="Country">Property Manager:</label>
                                            <div className="detail">John Smith</div>
                                            <div className="detail">johnsmith@gmail.com</div>
                                            <div className="detail">0909-08908989</div>
                                        </li>
                                        <li><label for="Country">Facility Manager:</label>
                                            <div className="detail">John Smith</div>
                                            <div className="detail">johnsmith@gmail.com</div>
                                            <div className="detail">0909-08908989</div>
                                        </li>
                                        <li><label for="Country">Emergency:</label>
                                            <div className="detail">John Smith</div>
                                            <div className="detail">johnsmith@gmail.com</div>
                                            <div className="detail">0909-08908989</div>
                                        </li>
                                        <li><label for="Country">Security Number:</label>
                                            <div className="detail">John Smith</div>
                                            <div className="detail">johnsmith@gmail.com</div>
                                            <div className="detail">0909-08908989</div>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="col-md-4">
                        <div className="photogrid">
                            <li><img src={"/lbs/images/bilder_2_0.jpeg"} alt="" /></li>
                            <li><img src={"/lbs/images/bilder_2_02.jpeg"} alt="" /></li>
                            <li><img src={"/lbs/images/bilder_2_03.jpeg"} alt="" /></li>
                            <li><img src={"/lbs/images/bilder_2_04.jpeg"} alt="" /></li>
                        </div>
                    </div>
                </div>


                <div className="col-md-12 addpropertyForm row noformcss mt-3">
                    <div className="col-md-8 d-flex justify-content-start">
                        <div className="titleanddetail">
                            <div className="pageslug">
                                <a>Tenants</a>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end">
                        <button type="submit" className="btn2 btn-primary">Export</button>
                        <button type="submit" className="btn btn-primary">Create New Ticket</button>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="menulist">
                        <ul>
                            <li><Link to="#" className="active">All</Link></li>
                            <li><Link to="#">Contract Inquiries</Link></li>
                            <li><Link to="#">Tenant Services</Link></li>
                            <li><Link to="#">Deficiency Message</Link></li>
                            <li><Link to="#">Other Request</Link></li>
                        </ul>
                    </div>
                    <div className="filterlist">
                        <ul>
                            <li className="active"><Link to="#">Open</Link></li>
                            <li><Link to="#">Close</Link></li>
                            <li><Link to="#">In Progress</Link></li>

                        </ul>
                    </div>
                    <div className="tenantsTablemyticket">
                        <table className="table teantsTableinpropertydetails myticket">
                            <thead>
                                <tr>
                                    <th scope="col">Requester</th>
                                    <th scope="col">Subject</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Last Message</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row"><img src={"/lbs/images/profile.png"} alt="" />
                                        <span className="moredetails">
                                            <div className="name">David Smith</div>
                                            <div className="email">davidsmith@gmail.com</div>
                                        </span>
                                    </th>
                                    <td>Ac sit etiam velit, consequat est augue commodo non.</td>
                                    <td><button className="status-btn in-progress">In Progress</button></td>
                                    <td>4 seconds ago</td>
                                    <td><Link to="/contact"><i className="fa-solid fa-ellipsis"></i></Link></td>
                                </tr>
                                <tr>
                                    <th scope="row"><img src={"/lbs/images/profile.png"} alt="" />
                                        <span className="moredetails">
                                            <div className="name">David Smith</div>
                                            <div className="email">davidsmith@gmail.com</div>
                                        </span>
                                    </th>
                                    <td>Ac sit etiam velit, consequat est augue commodo non.</td>
                                    <td><button className="status-btn in-progress">In Progress</button></td>
                                    <td>4 seconds ago</td>
                                    <td><Link to="/contact"><i className="fa-solid fa-ellipsis"></i></Link></td>
                                </tr>
                                <tr>
                                    <th scope="row"><img src={"/lbs/images/profile.png"} alt="" />
                                        <span className="moredetails">
                                            <div className="name">David Smith</div>
                                            <div className="email">davidsmith@gmail.com</div>
                                        </span>
                                    </th>
                                    <td>Ac sit etiam velit, consequat est augue commodo non.</td>
                                    <td><button className="status-btn in-progress">In Progress</button></td>
                                    <td>4 seconds ago</td>
                                    <td><Link to="/contact"><i className="fa-solid fa-ellipsis"></i></Link></td>
                                </tr>
                                <tr>
                                    <th scope="row"><img src={"/lbs/images/profile.png"} alt="" />
                                        <span className="moredetails">
                                            <div className="name">David Smith</div>
                                            <div className="email">davidsmith@gmail.com</div>
                                        </span>
                                    </th>
                                    <td>Ac sit etiam velit, consequat est augue commodo non.</td>
                                    <td><button className="status-btn in-progress">In Progress</button></td>
                                    <td>4 seconds ago</td>
                                    <td><Link to="/contact"><i className="fa-solid fa-ellipsis"></i></Link></td>
                                </tr>




                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}
