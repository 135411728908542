import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function forgetpass() {
  return (
    <>
    
    <div className="page">
        <div className="loginpage">
            <div className="leftsection">
               <div className="logo">
                    <img src={"/lbs/images/vlrlogo.png"} alt=""/>
               </div>
               <div className="page-sub-heading">
                   <p>Welcome to JamesHelps! After logging in, I will be happy to help.</p>
               </div>
            </div>
            <div className="rightsection">
                    <div className="formcontainer">
                        <div className="pagetitle">Forgot your password?</div>
                        <div className="subtitle">Enter your email and we'll send you a reset link.</div>
                        <form action="?" method="post">
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="exampleFormControlInput1"/>
                              </div>
                              
                              <button type="submit" className="btn btn-primary">Send reset link</button>
                        </form>
                        <div className="d-flex justify-content-start forgetpass"><a href="#">I can't recover my account using this page </a></div>
              
                    </div>
            </div>
        </div>
    </div>
    </>
  )
}
