import React from 'react'
import { Link } from 'react-router-dom'

export default function jourflexcreatenew() {

  const linkredirect = (e) => {

    window.location.href = '#';
  }
  const blockfilter = () => {
    document.querySelector(".filtercontainer").classList.add("block");
  }
  const removefilter = () => {
    document.querySelector(".filtercontainer").classList.remove("block");
  }

  return (
    <>
      <div className="dashboard performance our-flex-create-new ">

        <div className="titleanddetail performance-header">
          <div className='col-md-7 justify-content-start d-block m-4'>
            <div className="pageslug">
              <a>Lakewood Property - Status and Performance Overview
              </a>
            </div>
            <div className='titleandsub'>
              <div className='sub date'>10.11.2022 <img src={'./lbs/images/calender-blue.png'} /></div>
            </div>
          </div>

          <div className='col-md-5 d-flex align-items-center'>
            <div className='col-md-7'>

            </div>
            <div className='col-md-4 justify-content-end d-flex'>
              <a className='btn3 mx-3 mb-3'>Add Point</a>
              <select id="inputState" className="form-select">
                <option selected="">ENG</option>

              </select>

            </div>
          </div>

        </div>

        <div className='performance-table-container'>

          <div className="tenantsTable">
            <table className="table teantsTableinpropertydetails propertydetails2 performance">
              <thead>
                <tr>
                  <th scope="col">Sr.</th>
                  <th scope="col">Theme</th>
                  <th scope="col">Measure</th>
                  <th scope="col">To-Do</th>
                  <th scope="col">Responsible</th>
                  <th scope="col">Completion Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Process Lights</th>
                  <th scope="col">Action</th>

                </tr>
              </thead>
              <tbody>

                <tr onClick={linkredirect}>

                  <td>01</td>
                  <td>Turpis tellus semper placerat
                    magna. Sodales at convallis
                    consequat fringilla amet
                    maecenas libero pretium.</td>
                  <td>Measure title here</td>
                  <td>To-do title here</td>
                  <td>Responsibilities detail here</td>
                  <td>Responsibilities detail here</td>
                  <td>10.10.2022</td>

                  <td>
                    <div className='status-btn green active'></div>
                    <div className='status-btn yellow'></div>
                    <div className='status-btn red'></div>
                  </td>
                  <td className='actioncol'>
                    <div className='action-btn'>
                      <img src={'./lbs/images/save-3.png'} />
                    </div>
                    <div className='action-btn'>
                      <img src={'./lbs/images/edit-3.png'} />
                    </div>
                    <div className='action-btn'>
                      <img src={'./lbs/images/delete-3.png'} />
                    </div>

                  </td>

                </tr>
                <tr onClick={linkredirect}>

                  <td>01</td>
                  <td>Turpis tellus semper placerat
                    magna. Sodales at convallis
                    consequat fringilla amet
                    maecenas libero pretium.</td>
                  <td>Measure title here</td>
                  <td>To-do title here</td>
                  <td>Responsibilities detail here</td>
                  <td>Responsibilities detail here</td>
                  <td>10.10.2022</td>

                  <td>
                    <div className='status-btn green active'></div>
                    <div className='status-btn yellow'></div>
                    <div className='status-btn red'></div>
                  </td>
                  <td className='actioncol'>
                    <div className='action-btn'>
                      <img src={'./lbs/images/save-3.png'} />
                    </div>
                    <div className='action-btn'>
                      <img src={'./lbs/images/edit-3.png'} />
                    </div>
                    <div className='action-btn'>
                      <img src={'./lbs/images/delete-3.png'} />
                    </div>

                  </td>

                </tr>
                <tr onClick={linkredirect}>

                  <td>01</td>
                  <td>Turpis tellus semper placerat
                    magna. Sodales at convallis
                    consequat fringilla amet
                    maecenas libero pretium.</td>
                  <td>Measure title here</td>
                  <td>To-do title here</td>
                  <td>Responsibilities detail here</td>
                  <td>Responsibilities detail here</td>
                  <td>10.10.2022</td>

                  <td>
                    <div className='status-btn green active'></div>
                    <div className='status-btn yellow'></div>
                    <div className='status-btn red'></div>
                  </td>
                  <td className='actioncol'>
                    <div className='action-btn'>
                      <img src={'./lbs/images/save-3.png'} />
                    </div>
                    <div className='action-btn'>
                      <img src={'./lbs/images/edit-3.png'} />
                    </div>
                    <div className='action-btn'>
                      <img src={'./lbs/images/delete-3.png'} />
                    </div>

                  </td>

                </tr>
                <tr onClick={linkredirect}>

                  <td>01</td>
                  <td>Turpis tellus semper placerat
                    magna. Sodales at convallis
                    consequat fringilla amet
                    maecenas libero pretium.</td>
                  <td>Measure title here</td>
                  <td>To-do title here</td>
                  <td>Responsibilities detail here</td>
                  <td>Responsibilities detail here</td>
                  <td>10.10.2022</td>

                  <td>
                    <div className='status-btn green active'></div>
                    <div className='status-btn yellow'></div>
                    <div className='status-btn red'></div>
                  </td>
                  <td className='actioncol'>
                    <div className='action-btn'>
                      <img src={'./lbs/images/save-3.png'} />
                    </div>
                    <div className='action-btn'>
                      <img src={'./lbs/images/edit-3.png'} />
                    </div>
                    <div className='action-btn'>
                      <img src={'./lbs/images/delete-3.png'} />
                    </div>

                  </td>

                </tr>






              </tbody>
            </table>
          </div>


        </div>


        <div className="titleanddetail align-items-start px-4">
          <div className='col-md-10 justify-content-start d-block'>

            <div className='col-md-12 row mt-4'>
              <div className="addpropertyForm p-4">
                <div class="col-12">
                  <div className="pageslug">
                    <a>General  Points + Learning
                    </a>
                  </div>
                  <textarea name="" id="" cols="30" rows="10" placeholder="" class="form-control" spellcheck="false"></textarea></div>
              </div>
            </div>


          </div>

          <div className='col-md-2 performance-header'>
            <a className='btn3 mx-5 w-58' onClick={blockfilter}>Read permission</a>
            <div className='containerodpermitionform'>
            
              <div className='filtercontainer'>
                <div className='filterform'>
                  <div className="titleanddis">

                    <div className="dis">
                      <div className="addpropertyForm">

                        <div className="title">Create a Read permission <div className='close' onClick={removefilter}><img src={"/lbs/images/close.png"} /></div></div>
                        <div className='subitle'>
                          Who should have access to the protocol?
                        </div>

                        <form className="row g-3">

                          <div className="col-md-12">
                            <label for="inputEmail4" className="form-label">Invite People</label>
                            <select id="inputState" className="form-select">
                              <option selected="">Invite People</option>
                              <option>...</option>
                            </select>
                          </div>



                          <div className="col-md-12 d-flex justify-content-end">

                            <button type="submit" className="btn btn-primary">Create</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>


      </div>
    </>
  )
}
