import React from 'react'
import { Link } from 'react-router-dom'

export default function genaccount() {
    return (
        <>
            <div className='dashboard'>
                <div className="titleanddetail">
                    <div className="pageslug">
                        <a href="#">Account</a>
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='menus'>
                        <ul>
                            <li><a href='#' className='active'>General</a></li>
                            <li><Link to='/account-setting'>Setting</Link></li>
                            <li><Link to='/security-setting'>Security</Link></li>
             

                        </ul>
                    </div>
                </div>

                <div className='col-md-12 mt-5 profile-details'>
                    <div className='left'>
                        <div className='image'><img src={'../lbs/images/profile.png'} /></div>
                        <div className='details'>
                            <div className='name'>Mia Smith </div>
                            <div className='title'>Müller Property Management GmbH</div>
                        </div>
                    </div>
                    <div className='right'><button >Edit your avatar</button></div>

                </div>
                <div className='col-md-12 mt-3 profile-details-edit'>
                    <div className="addpropertyForm">

                   

                        <form className="row g-3">


                            <div className="col-md-6">
                                <label for="inputEmail4" className="form-label">First Name</label>
                                <input type="text" className="form-control" id="inputPassword4" placeholder="Input your first name" />
                            </div>
                            <div className="col-md-6">
                                <label for="inputEmail4" className="form-label">Last Name</label>
                                <input type="text" className="form-control" id="inputPassword4" placeholder="Input your last name" />
                            </div>
                       
                            <div className="col-md-6">
                                <label for="inputEmail4" className="form-label">Email Address</label>
                                <input type="text" className="form-control" id="inputPassword4" placeholder="Input your first E-Mail Adresse" />
                            </div>
                            <div className="col-md-6">
                                <label for="inputEmail4" className="form-label">Number Phone</label>
                                <input type="text" className="form-control" id="inputPassword4" placeholder="Input your last name" />
                            </div>
                       



                            <div className="col-md-12 d-flex justify-content-center">

                                <button type="submit" className="btn btn-primary submitbtn">Save your changes</button>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </>
    )
}
