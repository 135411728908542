import React from 'react'

export default function budget() {
    const blockfilter = () => {
        document.querySelector(".filtercontainer").classList.add("block");
    }
    const removefilter = () => {
        document.querySelector(".filtercontainer").classList.remove("block");
        document.querySelector(".filtercontainer2").classList.remove("block");
        document.querySelector(".filtercontainer3").classList.remove("block");
    }
    const addnewrowinbuget = (e) => {
        document.querySelectorAll(".dashboard.budget table.table.teantsTableinpropertydetails tbody tr.last td input.addnew").forEach((el) => {
            el.classList.add('block');
        });
     
        document.querySelector(".dashboard.budget table.table.teantsTableinpropertydetails tbody tr.last td span.save").classList.add("block");
        e.target.classList.add("hide");
    }
    return (
        <>
            <div className="dashboard budget propertyDetailspage facility-manager Property-technical">
                <div className="col-md-12 addpropertyForm hide row">
                    <div className='col-md-1'>
                        <div className="pageslug">
                            <a>Budget</a>
                        </div>
                    </div>
                    <div className='col-md-7'>
                        <div className="bugetbuttons">
                            <a className='active'>2022</a>
                            <a>2023</a>
                            <a>2024</a>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-end">
                        <div className="titleanddetail">


                            <a href="#" className="btn detail export" >Export</a>
                            <a href="#" className="detail filter" onClick={blockfilter} ><img src={"/lbs/images/filter.png"} /></a>
                        </div>
                    </div>
                </div>

                <div className='filtercontainer'>
                    <div className='filterform'>
                        <div className="titleanddis">

                            <div className="dis">
                                <div className="addpropertyForm">

                                    <div className="title">Filter <div className='close' onClick={removefilter}><img src={"/lbs/images/close.png"} /></div></div>

                                    <form className="row g-3">

                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">Property No</label>
                                            <select id="inputState" className="form-select">
                                                <option selected="">Select Option</option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">Property Name</label>
                                            <input type="text" className="form-control" id="inputPassword4" placeholder="Type here" />
                                        </div>
                                        <div className="col-md-12">
                                            <label for="inputEmail4" className="form-label">Property Address</label>
                                            <input type="text" className="form-control" id="inputPassword4" placeholder="Type here" />
                                        </div>
                                        <div className="col-md-12">
                                            <label for="inputEmail4" className="form-label">Process</label>
                                            <input type="text" className="form-control" id="inputPassword4" placeholder="Type here" />
                                        </div>
                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">Cost</label>
                                            <input type="text" className="form-control" id="inputPassword4" placeholder="Type here" />
                                        </div>
                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">Budget</label>
                                            <select id="inputState" className="form-select">
                                                <option selected="">Select Option</option>
                                                <option>...</option>
                                            </select>
                                        </div>


                                        <div className="col-md-12 d-flex justify-content-end">

                                            <button type="submit" className="btn btn-primary">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='tabel-container'>
                    <div className="col-md-12 addpropertyForm row">
                        <div className='col-md-4'>
                            <div className="pageslug">
                                <a> Quarter 1 2022  </a>
                            </div>
                        </div>
                        <div className="col-md-8 d-flex justify-content-end">
                            <div className="titleanddetail">



                                <a href="#" className="detail filter" ><img src={"/lbs/images/actioneditblue.png"} /> Edit</a>
                            </div>
                        </div>
                    </div>

                    <div className="tenantsTable">
                        <table className="table teantsTableinpropertydetails propertydetails2">
                            <thead>
                                <tr>
                                    <th scope="col">Budget Position</th>
                                    <th scope="col">Measure No.</th>
                                    <th scope="col">Process</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Budget</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                
                                <tr className='last add'>
                                    <td>
                                        <span className='new' onClick={addnewrowinbuget}>+Add new</span>
                                
                                    
                                    </td>

                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1">
                                        <input type='text' placeholder='Type' className="form-control addnew" />

                                    </td>
                                    <td>
                                    <input type='text' placeholder='$100' className="form-control addnew" />
                    
                                    
                                    </td>
                                    <td>
                                        <input type='text' placeholder='$200' className="form-control addnew" />

                                        <span className='save'>Save</span>
     
                                        
                                    </td>

                                </tr>
                                <tr className='last'>
                                    <td>
                                      
                                        <span>Total</span>
                                    
                                    </td>

                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td>

                                    <span>$200</span>

                                    </td>
                                    <td>
                                        <span>$200</span>
                                        
                                    </td>

                                </tr>




                            </tbody>

                        </table>
                    </div>
                </div>
                <div className='tabel-container'>
                    <div className="col-md-12 addpropertyForm row">
                        <div className='col-md-4'>
                            <div className="pageslug">
                                <a> Quarter 2 2022  </a>
                            </div>
                        </div>
                        <div className="col-md-8 d-flex justify-content-end">
                            <div className="titleanddetail">



                                <a href="#" className="detail filter" ><img src={"/lbs/images/actioneditblue.png"} /> Edit</a>
                            </div>
                        </div>
                    </div>

                    <div className="tenantsTable">
                        <table className="table teantsTableinpropertydetails propertydetails2">
                            <thead>
                                <tr>
                                    <th scope="col">Budget Position</th>
                                    <th scope="col">Measure No.</th>
                                    <th scope="col">Process</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Budget</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                
                                <tr className='last add'>
                                    <td>
                                        <span className='new' onClick={addnewrowinbuget}>+Add new</span>
                                
                                    
                                    </td>

                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1">
                                        <input type='text' placeholder='Type' className="form-control addnew" />

                                    </td>
                                    <td>
                                    <input type='text' placeholder='$100' className="form-control addnew" />
                    
                                    
                                    </td>
                                    <td>
                                        <input type='text' placeholder='$200' className="form-control addnew" />

                                        <span className='save'>Save</span>
     
                                        
                                    </td>

                                </tr>
                                <tr className='last'>
                                    <td>
                                      
                                        <span>Total</span>
                                    
                                    </td>

                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td>

                                    <span>$200</span>

                                    </td>
                                    <td>
                                        <span>$200</span>
                                        
                                    </td>

                                </tr>




                            </tbody>

                        </table>
                    </div>
                </div>
                <div className='tabel-container'>
                    <div className="col-md-12 addpropertyForm row">
                        <div className='col-md-4'>
                            <div className="pageslug">
                                <a> Quarter 3 2022  </a>
                            </div>
                        </div>
                        <div className="col-md-8 d-flex justify-content-end">
                            <div className="titleanddetail">



                                <a href="#" className="detail filter" ><img src={"/lbs/images/actioneditblue.png"} /> Edit</a>
                            </div>
                        </div>
                    </div>

                    <div className="tenantsTable">
                        <table className="table teantsTableinpropertydetails propertydetails2">
                            <thead>
                                <tr>
                                    <th scope="col">Budget Position</th>
                                    <th scope="col">Measure No.</th>
                                    <th scope="col">Process</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Budget</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                
                                <tr className='last add'>
                                    <td>
                                        <span className='new' onClick={addnewrowinbuget}>+Add new</span>
                                
                                    
                                    </td>

                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1">
                                        <input type='text' placeholder='Type' className="form-control addnew" />

                                    </td>
                                    <td>
                                    <input type='text' placeholder='$100' className="form-control addnew" />
                    
                                    
                                    </td>
                                    <td>
                                        <input type='text' placeholder='$200' className="form-control addnew" />

                                        <span className='save'>Save</span>
     
                                        
                                    </td>

                                </tr>
                                <tr className='last'>
                                    <td>
                                      
                                        <span>Total</span>
                                    
                                    </td>

                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td>

                                    <span>$200</span>

                                    </td>
                                    <td>
                                        <span>$200</span>
                                        
                                    </td>

                                </tr>




                            </tbody>

                        </table>
                    </div>
                </div>
                <div className='tabel-container'>
                    <div className="col-md-12 addpropertyForm row">
                        <div className='col-md-4'>
                            <div className="pageslug">
                                <a> Quarter 4 2022  </a>
                            </div>
                        </div>
                        <div className="col-md-8 d-flex justify-content-end">
                            <div className="titleanddetail">



                                <a href="#" className="detail filter" ><img src={"/lbs/images/actioneditblue.png"} /> Edit</a>
                            </div>
                        </div>
                    </div>

                    <div className="tenantsTable">
                        <table className="table teantsTableinpropertydetails propertydetails2">
                            <thead>
                                <tr>
                                    <th scope="col">Budget Position</th>
                                    <th scope="col">Measure No.</th>
                                    <th scope="col">Process</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Budget</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                
                                <tr className='last add'>
                                    <td>
                                        <span className='new' onClick={addnewrowinbuget}>+Add new</span>
                                
                                    
                                    </td>

                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1">
                                        <input type='text' placeholder='Type' className="form-control addnew" />

                                    </td>
                                    <td>
                                    <input type='text' placeholder='$100' className="form-control addnew" />
                    
                                    
                                    </td>
                                    <td>
                                        <input type='text' placeholder='$200' className="form-control addnew" />

                                        <span className='save'>Save</span>
     
                                        
                                    </td>

                                </tr>
                                <tr className='last'>
                                    <td>
                                      
                                        <span>Total</span>
                                    
                                    </td>

                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td>

                                    <span>$200</span>

                                    </td>
                                    <td>
                                        <span>$200</span>
                                        
                                    </td>

                                </tr>




                            </tbody>

                        </table>
                    </div>
                </div>
                <div className='tabel-container'>
                    <div className="col-md-12 addpropertyForm row">
                        <div className='col-md-4'>
                            <div className="pageslug">
                                <a> Quarter 5 2022  </a>
                            </div>
                        </div>
                        <div className="col-md-8 d-flex justify-content-end">
                            <div className="titleanddetail">



                                <a href="#" className="detail filter" ><img src={"/lbs/images/actioneditblue.png"} /> Edit</a>
                            </div>
                        </div>
                    </div>

                    <div className="tenantsTable">
                        <table className="table teantsTableinpropertydetails propertydetails2">
                            <thead>
                                <tr>
                                    <th scope="col">Budget Position</th>
                                    <th scope="col">Measure No.</th>
                                    <th scope="col">Process</th>
                                    <th scope="col">Description</th>
                                    <th scope="col">Cost</th>
                                    <th scope="col">Budget</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>10</td>
                                    <td>Construction</td>
                                    <td >

                                        <span>Brief description of the measure</span>
                                        

                                    </td>
                                    <td >

                                        <span>$150</span>
                                       

                                    </td>
                                    <td >

                                        <span>$200</span>
                                        
                                    </td>

                                </tr>
                                
                                <tr className='last add'>
                                    <td>
                                        <span className='new' onClick={addnewrowinbuget}>+Add new</span>
                                
                                    
                                    </td>

                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1">
                                        <input type='text' placeholder='Type' className="form-control addnew" />

                                    </td>
                                    <td>
                                    <input type='text' placeholder='$100' className="form-control addnew" />
                    
                                    
                                    </td>
                                    <td>
                                        <input type='text' placeholder='$200' className="form-control addnew" />

                                        <span className='save'>Save</span>
     
                                        
                                    </td>

                                </tr>
                                <tr className='last'>
                                    <td>
                                      
                                        <span>Total</span>
                                    
                                    </td>

                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td colspan="1"></td>
                                    <td>

                                    <span>$200</span>

                                    </td>
                                    <td>
                                        <span>$200</span>
                                        
                                    </td>

                                </tr>




                            </tbody>

                        </table>
                    </div>
                </div>
             
            </div>
        </>
    )
}
