import React from 'react'

export default function create() {
    return (
        <>
            <div className="dashboard">

                <div className="titleanddetail">
                    <span className="title">Create Process</span>

                </div>
                <div className="addpropertyForm">

                    <div className="formTitle">
                    Create new approval process
                    </div>


                    <form className="row g-3">
                        <div className="col-md-6">
                            <label for="inputEmail4" className="form-label">Property No</label>
                            <select id="inputState" className="form-select">
                                <option selected="">Select Option</option>
                                <option>...</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label for="inputEmail4" className="form-label">Property Type</label>
                            <select id="inputState" className="form-select">
                                <option selected="">Select Option</option>
                                <option>...</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label for="inputEmail4" className="form-label">Process</label>
                            <select id="inputState" className="form-select">
                                <option selected="">Select Option</option>
                                <option>...</option>        
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label for="inputEmail4" className="form-label">Assignee</label>
                            <select id="inputState" className="form-select">
                                <option selected="">Select Option</option>
                                <option>Property Manager</option>
                                <option>Property Manager Technical</option>
                                <option>Asset Manager</option>
                                <option>Asset Manager</option>
                                <option>Investor</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label for="inputEmail4" className="form-label">Cost</label>
                            <select id="inputState" className="form-select">
                                <option selected="">Select Option</option>
                                <option>...</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label for="inputEmail4" className="form-label">Budget</label>
                            <select id="inputState" className="form-select">
                                <option selected="">Select Option</option>
                                <option>...</option>
                            </select>
                        </div>
                        <div className="col-12">
                            <label for="inputAddress" className="form-label">Description *</label>
                            <textarea name="" id="" cols="30" rows="10" placeholder="Type here" className="form-control"></textarea>
                        </div>
                        <div className="col-12">

                            <div className="fileup">Upload</div>
                        </div>

                        <div className="col-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary">Create</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
