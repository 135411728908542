import React, { useState } from 'react'
import { Outlet, Link,useMatch,
  useResolvedPath,} from "react-router-dom";
  
export default function Sidebar( children, to, ...props) {
  let resolved = useResolvedPath(to);
  console.log(resolved);
  let match = resolved.pathname;
  const [menu ,setmenu]=useState('hide');

  const parentsidemenuclick= (event)=>{
    
  
    event.currentTarget.classList.toggle('active');
  if(menu === 'hide'){
    setmenu("show");
  }else{
    setmenu("hide");
  }
   
}  

  return (
    <>
      <div className="sidenav">
    <div className="logo">
    <Link to="/"><img src={"/lbs/images/vlrlogo.png"} alt=""/> </Link>
    </div>

    <ul className='firstone'>
    
    

    <span className='parent' onClick={parentsidemenuclick}>Kriegsbergstraße 11, Stuttgart<i></i></span>
    {menu ==='show'?
    
    
    <>
      <li className={match === '/property-details' ? "active":match === '/' ? "active":''}><Link to="/property-details"><img src={"/lbs/images/dblogo.png"} alt=""/> <span>Property</span></Link> </li>
      {/* <li className={match === '/property-list' ? "active":""}><Link to="/property-list"><img src={"/lbs/images/Property.png"} alt=""/> <span>Property Detail</span></Link></li> */}
      <li className={match === '/property-property-approval-process' || match === '/property-painting-process' ? "active" :""}><Link to="/property-property-approval-process"><img src={"/lbs/images/dbapproval.png"} alt=""/> <span>Approval Processes</span></Link></li>
      <li className={match === '/property-chat' ? "active":""} ><Link to="/property-chat"><img src={"/lbs/images/dbchat.png"} alt=""/> <span>Chat</span></Link></li>
      <li className={match === '/Property-budget' ? "active":""} ><Link to="/Property-budget"><img src={"/lbs/images/dbbuget.png"} alt=""/> <span>Budget</span></Link></li>
      <li className={match === '/property-jour' ? "active":""} ><Link to="/property-jour"><img src={"/lbs/images/dbjour.png"} alt=""/> <span>Jour FIxe</span></Link></li>
      <li className={match === '/myticket' ? "active":""}><Link to="/myticket"><img src={"/lbs/images//My Tickets.png"} alt=""/> <span>Tickets</span></Link></li>
      <li className={match === '/performance' ? "active":""}><Link to="/performance"><img src={"/lbs/images/performance-icon.png"} alt=""/> <span>Performance Center</span></Link></li>
    </>
    :''}
    


    <Outlet />
  </ul>
  <ul className='secoundone'>
      <div className='logoutandsettingmenu'>
            <li className={match === '/genarel-setting' ? "active":""}><Link to="/genarel-setting"><img src={"/lbs/images/setting.png"} alt=""/> <span>Setting</span></Link></li>
            <li className={match === '/' ? "active":""}><Link to="/"><img src={"/lbs/images/logout.png"} alt=""/> <span>Logout</span></Link></li>
            <li className={match === '/addproperty' ? "active":""}><Link to="/addproperty"><img src={"/lbs/images/3 User.png"} alt=""/> <span>Add new Property</span></Link></li>
            
        </div>
  </ul>
  </div>
    </>
  )
}
