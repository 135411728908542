import React from 'react'
import { Link } from 'react-router-dom'

export default function edit() {
    return (
        <>
            <div className="dashboard propertyDetailspage modification-requests">

                <div className="titleanddetail">Modification Requests</div>

                <div className="row">
                    <div className="col-md-12">
                        <ul className='modification-requests-list'>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>
                            <li>
                                <Link to="/facility-manager-modification-requests-view/">
                                    <div className="titleanddis">

                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Request By:</label>
                                                        <div className="detail">Property Manager</div>

                                                    </li>
                                                    <li><label for="Country">Process:</label>
                                                        <div className="detail">Painting Process</div>

                                                    </li>
                                                    <div className='time'>24 min ago</div>


                                                </ul>
                                            </div>
                                        </div>
                                        <div className="dis">
                                            <div className="detail">
                                                <ul>
                                                    <li><label for="Country">Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                                        Senectus non et penatibus</label>

                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                            </li>

                        </ul>

                    </div>

                </div>




            </div>
        </>
    )
}
