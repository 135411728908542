import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function contact() {
  return (
    <>
    <div className="dashboard contact-inquiryPage">

<div className="titleanddetail">
    <div className="pageslug">
        <a>Contract Inquiry</a>

    </div>

</div>
<div className="row">
    <div className="col-md-12">

        <div className="contact-profile">
            <div className="col-md-12">
                <div className="profile-detailes">
                    <div className="imageContainer">
                        <img src={"/lbs/images/profile.png"} alt=""/>
                        <div className="nameandtitle">
                            <a>Joe Jones</a>
                            <span>Landlord</span>
                        </div>
                    </div>
                    <div className="dis">
                        Diam, in vitae ante velit suscipit scelerisque commodo urna at. Arcu quam erat ac
                        volutpat sed nunc faucibus id. Tempor adipiscing ultricies odio ipsum sed quis amet,
                        mauris. Diam, in vitae ante velit suscipit scelerisque commodo urna at. Arcu quam
                        erat ac volutpat sed nunc faucibus id. Tempor adipiscing ultricies odio ipsum sed
                        quis amet, mauris. Diam, in vitae ante velit suscipit scelerisque commodo urna at.
                        Arcu quam erat ac volutpat sed nunc faucibus id. Tempor adipiscing ultricies odio
                        ipsum sed quis amet, mauris. Diam, in vitae ante velit suscipit scelerisque commodo
                        urna at. Arcu quam erat ac volutpat sed nunc faucibus id.
                    </div>
                </div>
            </div>
        </div>
        <div className="contact-query-box">

            <div className="titleanddetail">
                <div className="pageslug">
                    <a>Contract Inquiry</a>

                </div>
                <button className="status-btn in-progress">In Progress</button>
            </div>
            <div className="col-md-12">
                <div className="imageContainer">
                    <img src={"/lbs/images/profile.png"} alt=""/>
                    <div className="nameandtitle">
                        <a className="subject-header">Have an issue in my contract</a>
                        <span className="date">
                            <ul>
                                <li>David Smith</li>
                                <li>21 Feb 2020</li>
                                <li>Last Reply: 24 min ago</li>

                            </ul>
                        </span>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="chatlist">
                    <ul>
                        <li>
                            <div className="imageandmsg">
                                <div className="imageanddate">
                                    <img src={"lbs/images/profile.png"} alt=""/>
                                    <span>6:21 PM</span>
                                    <span>4th July</span>
                                </div>
                                <div className="message">
                                    Cras sit amet nibh libero, in gravida nulla. Nulla vel met scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis.
                                </div>
                            </div>
                        </li>
                        <li className="me">
                            <div className="imageandmsg">
                                <div className="imageanddate">
                                    <img src={"lbs/images/profile.png"} alt=""/>
                                    <span>6:21 PM</span>
                                    <span>4th July</span>
                                </div>
                                <div className="message">
                                    Cras sit amet nibh libero, in gravida nulla. Nulla vel met scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis.
                                </div>
                            </div>
                        </li>
                        
                    </ul>
                </div>
                <div className="sendmsgbox">
                    <form action="" method="post">
                        <input type="text" name="" id="" placeholder="Please answer..."/>
                        <ul>
                            <li><img src={"/lbs/images/importfile.png"} alt=""/></li>
                            <li><button type="submit" className="btn">Reply</button></li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>

    </div>

</div>


</div>
    </>
  )
}
