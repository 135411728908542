import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function addproperty() {
  return (
    <>
    <div className="dashboard">

<div className="titleanddetail">
  <span className="title">Add New Property</span>
 
</div>
<div className="addpropertyForm">

   <div className="formTitle">
    Property Details
   </div>


<form className="row g-3">
  <div className="col-md-6">
    <label for="inputEmail4" className="form-label">Country *</label>
    <select id="inputState" className="form-select">
      <option selected="">Select Option</option>
      <option>...</option>
    </select>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">City *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-12">
    <label for="inputAddress" className="form-label">Address *</label>
    <select id="inputState" className="form-select">
      <option selected="">Select Option</option>
      <option>...</option>
    </select>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Floor *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Building *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Street *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Squaremeter *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Landlord of Property *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Contact Security Person of Property *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-12 imageupload">
    <label for="inputAddress" className="form-label">Images *</label>
    <ul>
      <li><img src={"/lbs/images/upload image.png"} alt="" /></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>

    </ul>
  </div>
  <div className="formTitle">
    Contract Details
   </div>

   <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Agreement Partner *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Landlord *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Property *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Payment *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Payment System *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-12 d-flex justify-content-end">
    <button type="submit" className="btn2 btn-primary">Add New Contract</button>
    <button type="submit" className="btn btn-primary">Save</button>
  </div>
</form>
</div>
</div>
    </>
  )
}
