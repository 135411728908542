import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function addticket() {
  return (
    <>
    <div className="dashboard">

<div className="titleanddetail">
  <span className="title">New Ticket</span>
 
</div>
<div className="addpropertyForm">

   <div className="formTitle">
      Create new ticket
   </div>


<form className="row g-3">
  <div className="col-md-6">
    <label for="inputEmail4" className="form-label">Request Type *</label>
    <select id="inputState" className="form-select">
      <option selected="">Select Option</option>
      <option>...</option>
    </select>
  </div>
  <div className="col-md-6">
    <label for="inputPassword4" className="form-label">Subject *</label>
    <input type="text" className="form-control" id="inputPassword4" placeholder="Type here"/>
  </div>
  <div className="col-12">
    <label for="inputAddress" className="form-label">Description *</label>
    <textarea name="" id="" cols="30" rows="10" placeholder="Type here"  className="form-control"></textarea>
  </div>
  <div className="col-12">

    <div className="fileup">Upload</div>
  </div>
 
  <div className="col-12 d-flex justify-content-end">
    <button type="submit" className="btn btn-primary">Submit</button>
  </div>
</form>
</div>
</div>
    </>
  )
}
