import React from 'react'
import { Outlet, Link } from "react-router-dom";
export default function property() {
  return (
    <>
    <div className="dashboard propertyDetailspage">

<div className="titleanddetail">
    <div className="pageslug">
        <li><a href="#">All Properties</a></li>
        <li><a href="#">Property Detail</a></li>
    </div>
    <a href="#" className="btn detail">Update</a>
</div>
<div className="row">
    <div className="col-md-8">
        <div className="titleanddis">
            <div className="title">Property Details: </div>
            <div className="dis">
                <div className="detail">
                    <ul>
                        <li><label for="Country">Country:</label>
                            <div className="detail">United States of America</div>
                        </li>
                        <li><label for="City">City:</label>
                            <div className="detail">New York</div>
                        </li>
                        <li><label for="Street">Street:</label>
                            <div className="detail">Street #1</div>
                        </li>
                        <li><label for="Address:">Address:</label>
                            <div className="detail">4517 Washington Ave. Manchester, Kentucky 39495</div>
                        </li>
                        <li><label for="Building">Building:</label>
                            <div className="detail">Building #1</div>
                        </li>
                        <li><label for="Squaremeters">Squaremeters:</label>
                            <div className="detail">272sqm</div>
                        </li>
                        <li><label for="Floor">Floor:</label>
                            <div className="detail">Floor #1</div>
                        </li>
                        <li><label for="Number of Tenants:">Number of Tenants:</label>
                            <div className="detail">8</div>
                        </li>
                        <li><label for="Contact Security Person of Property">Contact Security Person of
                                Property:</label>
                            <div className="detail">(406) 555-0120</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="titleanddis">
            <div className="title">Contract Details:</div>
            <div className="dis">
                <div className="detail">
                    <ul>
                        <li><label for="Country">Tenant:</label>
                            <div className="detail">Mia Smith</div>
                        </li>
                        <li><label for="City">Landlord:</label>
                            <div className="detail">Real Estate Company</div>
                        </li>
                        <li><label for="Street">Property:</label>
                            <div className="detail">House</div>
                        </li>
                        <li><label for="Address:">Payments:</label>
                            <div className="detail">3.000 € per month </div>
                        </li>
                        <li><label for="Building">Payment system:</label>
                            <div className="detail">Bank Transfer</div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
        <div className="titleanddis contactDetailsbyposition">
            <div className="title">Contact Details:</div>
            <div className="dis">
                <div className="detail">
                    <ul>
                        <li><label for="Country">Property Manager:</label>
                            <div className="detail">John Smith</div>
                            <div className="detail">johnsmith@gmail.com</div>
                            <div className="detail">0909-08908989</div>
                        </li>
                        <li><label for="Country">Facility Manager:</label>
                            <div className="detail">John Smith</div>
                            <div className="detail">johnsmith@gmail.com</div>
                            <div className="detail">0909-08908989</div>
                        </li>
                        <li><label for="Country">Emergency:</label>
                            <div className="detail">John Smith</div>
                            <div className="detail">johnsmith@gmail.com</div>
                            <div className="detail">0909-08908989</div>
                        </li>
                        <li><label for="Country">Security Number:</label>
                            <div className="detail">John Smith</div>
                            <div className="detail">johnsmith@gmail.com</div>
                            <div className="detail">0909-08908989</div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div className="col-md-4">
        <div className="photogrid">
            <li><img src={"/lbs/images/photo1.png"} alt=""/></li>
            <li><img src={"/lbs/images/photo2.png"} alt=""/></li>
            <li><img src={"/lbs/images/photo3.png"} alt=""/></li>
            <li><img src={"/lbs/images/photo4.png"} alt=""/></li>
        </div>
    </div>
</div>
<div className="titleanddetail">
    <div className="pageslug">
        <a>Tenants</a>

    </div>
</div>
<div className="tenantsTable">
    <table className="table teantsTableinpropertydetails">
        <thead>
            <tr>
                <th scope="col">Tenants</th>
                <th scope="col">Email</th>
                <th scope="col">Phone Number</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <th scope="row"><img src={"/lbs/images/profile.png"} alt=""/> Julia Smith</th>
                <td>dolores.chambers@example.com</td>
                <td>(671) 555-0110</td>
                <td><Link to="/property-modify"><i className="fa-solid fa-ellipsis"></i></Link></td>
            </tr>

            <tr>
                <th scope="row"><img src={"/lbs/images/profile.png"} alt=""/> Julia Smith</th>
                <td>dolores.chambers@example.com</td>
                <td>(671) 555-0110</td>
                <td><Link to="/property-modify"><i className="fa-solid fa-ellipsis"></i></Link></td>
            </tr>

            <tr>
                <th scope="row"><img src={"/lbs/images/profile.png"} alt=""/> Julia Smith</th>
                <td>dolores.chambers@example.com</td>
                <td>(671) 555-0110</td>
                <td><Link to="/property-modify"><i className="fa-solid fa-ellipsis"></i></Link></td>
            </tr>

        </tbody>
    </table>
</div>
</div>
    </>
  )
}
