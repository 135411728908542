import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Outlet, Link,useMatch,
  useResolvedPath,useNavigate} from "react-router-dom";
import { logout } from '../../redux/userslice';
export default function Header() {
  const history = useNavigate();
  const dispatch=useDispatch();
  const [name ,setName]=useState('');

  useEffect(() => {
      const useremail=localStorage.getItem('email');
      
      if(useremail){
        setName(useremail);
      }else{
        history('/login');
      }
  }, []);
  
  const userlogout=()=>{
    dispatch(logout());
    localStorage.clear();
    history('/login');
  }
  return (
    <>
  <header>

<nav>
  <ul>
    <li className="notification">
      <Link to="/"><img src={"/lbs/images/notification.png"} alt=""/></Link>
    </li>
    <li className="profile">
      <img src={"/lbs/images/profile.png"} className="profileImage" alt=""/>
      <span className="profileholder">{name} <div className="icon"><img src={"/lbs/images/downarray.png"} alt=""/>
        </div></span>
      <ul>
        <li><a href="#">View Profile</a></li>
        <li><a href="#">Update Profile</a></li>
        <li><a href="#">Settings</a></li>
        <li><a onClick={userlogout}>Log Out</a></li>
      </ul>
    </li>
  </ul>
</nav>
</header>
    </>
  )
}
