import React, { useEffect, useState } from 'react'
import { Link,useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { login, selectUser } from '../../redux/userslice';
import axios from 'axios';
import Auth from '../../test/auth';


export default function Login() {
  const {http}=Auth();
  const history = useNavigate();
const [email,setEmail]=useState("");
const [pass,setPass]=useState("");
const despatch=useDispatch();
const user =useSelector(selectUser);
const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
};

  const handerLogin =(e)=>{
    e.preventDefault();

    // axios.get('http://localhost:8000/test',{email:email,pass:pass},headers).then((res)=>{
    //   console.log(res.data);
    // });
    despatch(login({
      email:email,
      pass:pass
    }));

    if(email === 'demo@gmail.com' && pass === 'demo@12345'){
      localStorage.setItem('email',email);
      history('/performance');
    }else{
      alert("Wrong info!");
    }
    // localStorage.setItem('email',user.email);
    // history('/home');
    

  }



  useEffect(()=>{

    const checkUser=localStorage.getItem('email');

    if(checkUser){
      history('/performance');
    }
    

  },[]);
  

  return (
    <>
        <div className="page">
        <div className="loginpage">
            <div className="leftsection">
               <div className="logo">
                    <img src={"/lbs/images/vlrlogo.png"} alt=""/>
               </div>
               <div className="page-sub-heading">
                   <p>Welcome to {user?user.email:null} JamesHelps! After logging in, I will be happy to help.</p>
               </div>
            </div>
            <div className="rightsection">
                    <div className="formcontainer">
                        <div className="pagetitle">Account Login</div>
                        <form method="post" onSubmit={handerLogin}>
                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Email address</label>
                                <input type="email" className="form-control" id="exampleFormControlInput1" 
                                value={email} 
                                onChange={(e)=>setEmail(e.target.value)}
                                
                                />
                              </div>
                              <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label">Password</label>
                                <input type="password" className="form-control" id="exampleFormControlInput1"
                                   value={pass} 
                                   onChange={(e)=>setPass(e.target.value)}
                                />
                              </div>
                              <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="checkbox"/>
                                <label className="form-check-label" htmlFor="checkbox">
                                    Remember me
                                </label>
                              </div>
                              <button type="submit" className="btn btn-primary">Log in</button>
                        </form>
                        <div className="d-flex justify-content-end forgetpass"><Link to="/forgetpass">Forget Password?</Link></div>
                        <div className="d-flex justify-content-center needaccount">Don't have an account? <Link to="/registration">Get started!</Link></div>
                    </div>
            </div>
        </div>
    </div>
    </>
  )
}
