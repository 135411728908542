import React from 'react'
import { Link } from 'react-router-dom'

export default function requestview() {
    return (
        <>
            <div className="dashboard propertyDetailspage requestview-modification">

                <div className="titleanddetail">
                    <div className="pageslug">
                        <li><Link to="/facility-manager-modification-requests">Modification Requests</Link></li>
                        <li><Link to="#">Request 1</Link></li>
                    </div>

                </div>
          
                <div className="row">
                    <div className="col-md-12">

                        <div className="titleanddis contactDetailsbyposition">
                            <div className="title normal">Process Overview <div className='right'><li><a href='#'><img src={"/lbs/images/actioneditblue.png"} /><span>Edit</span></a></li></div></div>
                            <div className="dis">
                                <div className="detail">
                                    <ul>
                                    
                                        <li><label for="Country">Property Type:</label>
                                            <div className="detail">Home</div>

                                        </li>
                                    
                                        <li><label for="Country">Assignee:</label>
                                            <div className="detail">Property Manager</div>

                                        </li>
                                        <li><label for="Country">Cost:</label>
                                            <div className="detail">$250</div>

                                        </li>
                                        <li><label for="Country">Budget:</label>
                                            <div className="detail">$150</div>

                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="titleanddis contactDetailsbyposition">
                            <div className="title normal">Description:</div>
                            <div className="dis">
                                <div className='detail'>
                                    <ul>

                                        Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                        Senectus non et penatibus magna sed ornare adipiscing. Sed arcu, adipiscing facilisis et magna vitae porttitor felis. Sit tellus luctus
                                        quam faucibus id diam nunc, at. Nec nisl, quisque aliquet phasellus condimentum vehicula a tellus blandit. Turpis ornare in bibendum
                                        et et, lobortis. Morbi commodo pretium dignissim pharetra, aenean et montes, non. Justo adipiscing vitae scelerisque consectetur
                                        consequat magna nibh. Eros, dui, fermentum elit sem lorem facilisis tortor ut. Morbi mi pellentesque feugiat vehicula ultrices felis lectus.
                                        Ut quis enim viverra vulputate. Odio ut malesuada posuere ut cursus velit commodo. Iaculis blandit dictumst vitae suspendisse auctor.
                                        Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                        Senectus non et penatibus magna sed ornare adipiscing. Sed arcu, adipiscing facilisis et magna vitae porttitor felis. Sit tellus luctus
                                        quam faucibus id diam nunc, at. Morbi mi pellentesque feugiat vehicula ultrices felis lectus.
                                        Ut quis enim viverra vulputate. Odio ut malesuada posuere ut cursus velit commodo.

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="titleanddis contactDetailsbyposition">
                            <div className="title">Modification Request</div>
                            <div className="dis">
                                <div className="detail">
                                    <ul>
                                   
                                        <li><label for="Country">Request By:</label>
                                            <div className="detail">Property Manager</div>

                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="titleanddis contactDetailsbyposition">
    
                            <div className="dis">
                                <div className='detail'>
                                        <ul>
                                        <li><label for="Country">Modification Description:</label></li>
                                        </ul>
                                        <ul>
                                        Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                        Senectus non et penatibus magna sed ornare adipiscing. Sed arcu, adipiscing facilisis et magna vitae porttitor felis. Sit tellus luctus
                                        quam faucibus id diam nunc, at. Nec nisl, quisque aliquet phasellus condimentum vehicula a tellus blandit. Turpis ornare in bibendum
                                        et et, lobortis. Morbi commodo pretium dignissim pharetra, aenean et montes, non. Justo adipiscing vitae scelerisque consectetur
                                        consequat magna nibh. Eros, dui, fermentum elit sem lorem facilisis tortor ut. Morbi mi pellentesque feugiat vehicula ultrices felis lectus.
                                        Ut quis enim viverra vulputate. Odio ut malesuada posuere ut cursus velit commodo. Iaculis blandit dictumst vitae suspendisse auctor.
                                        Tempor ultricies facilisis malesuada amet nisi blandit commodo. Justo tristique non, neque, sit mauris ullamcorper diam tellus et.
                                        Senectus non et penatibus magna sed ornare adipiscing. Sed arcu, adipiscing facilisis et magna vitae porttitor felis. Sit tellus luctus
                                        quam faucibus id diam nunc, at. Morbi mi pellentesque feugiat vehicula ultrices felis lectus.
                                        Ut quis enim viverra vulputate. Odio ut malesuada posuere ut cursus velit commodo.

                                    </ul>
                                </div>
                            </div>
                        </div>
                        
                    </div>

                </div>




            </div>
        </>
    )
}
