import React from 'react'
import { Link } from 'react-router-dom'


export default function jour() {
    const linkredirect = (e) => {

        window.location.href = "/jour-flex-create-new";
    }
    return (
        <>
            <div className="dashboard propertyDetailspage approvalprocess">

                <div className="titleanddetail">
                    <div className="pageslug">
                        {/* <li><Link to="#">Approval Process </Link></li>
                        <li><Link to="#">Painting Process</Link></li> */}
                        <a>Jour Fixe</a>
                    </div>

                </div>
                <div className='propertyList '>

                    <Link to="/property-details" className="property">
                        <div className="thumbanil">
                            <img src={"./lbs/images/bilder_2_0.jpeg"} alt="" />
                        </div>
                        <div className="detail">
                            <ul>
                                <li><label for="Country">Country:</label>
                                    <div className="detail">Germany</div>
                                </li>
                                <li><label for="City">City:</label>
                                    <div className="detail">Stuttgart</div>
                                </li>
                                <li><label for="Street">Street:</label>
                                    <div className="detail">Kriegsbergstraße 11</div>
                                </li>
                                <li><label for="Address:">Address:</label>
                                    <div className="detail">Kriegsbergstraße 11, 70174 Stuttgart, Germany</div>
                                </li>
                                <li><label for="Building">Building parts:</label>
                                    <div className="detail">1</div>
                                </li>
                                <li><label for="Squaremeters">Total area:</label>
                                    <div className="detail">16.000 sqm</div>
                                </li>
                                <li><label for="Floor">Rental space:</label>
                                    <div className="detail">10.000 sqm</div>
                                </li>
                                <li><label for="Number of Tenants:">Number of Tenants:</label>
                                    <div className="detail">4</div>
                                </li>
                                <li><label for="Number of Tenants:">Current vacancy: </label>
                                    <div className="detail">500 sqm</div>
                                </li>
                                <li><label for="Number of Tenants:">Number of floors:</label>
                                    <div className="detail">11</div>
                                </li>


                            </ul>
                        </div>
                    </Link>

                </div>
                <div className="row">
                    <div className="col-md-12">

                        <div className="titleanddis contactDetailsbyposition property-jour">
                            <div className="title normal">Process Overview <span style={{ color: '#39A207', background: '#eff8e3', fontSize: '12px', padding: '5px 12px', borderRadius: '10px' }}>Ongoing</span></div>
                            <div className="dis">
                                <div className="detail">
                                    <ul>
                                        <li><label for="Country">Host:</label>
                                            <div className="detail">Mia Smith</div>

                                        </li>
                                        <li><label for="Country">Teilnehmer:</label>
                                            <div className="detail">Mia Smith, John, David, Luke, Sarah</div>

                                        </li>



                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="titleanddis contactDetailsbyposition property-jour" id='list-table'>

                            <div className="dis">

                                <div className="tenantsTable">
                                    <table className="table teantsTableinpropertydetails propertydetails2">
                                        <thead>
                                            <tr>

                                                <th scope="col">Start Zeit:</th>
                                                <th scope="col">Ende</th>
                                                <th scope="col">Thema:</th>
                                                <th scope="col">Moderator:</th>


                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>

                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>

                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>

                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>

                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>

                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>

                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>

                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>

                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>

                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>

                                            <tr onClick={linkredirect} >
                                                <td>09:15</td>
                                                <td>10:00</td>
                                                <td>Zweiwöchiges Jour -Fixe</td>
                                                <td>Mia Smith</td>

                                            </tr>




                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>



            </div>
        </>
    )
}
