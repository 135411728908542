import React, { useState } from 'react'
import {
  Outlet, Link, useMatch,
  useResolvedPath,
} from "react-router-dom";

export default function Sidebar(children, to, ...props) {



  let resolved = useResolvedPath(to);
  console.log(resolved);
  let match = resolved.pathname;


  const [menu ,setmenu]=useState('hide');

  const parentsidemenuclick= (event)=>{
    
  
    event.currentTarget.classList.toggle('active');
  if(menu === 'hide'){
    setmenu("show");
  }else{
    setmenu("hide");
  }
   
}  
  return (
    <>
      <div className="sidenav">
        <div className="logo">
          <Link to="/"><img src={"/lbs/images/vlrlogo.png"} alt="" /> </Link>
        </div>

        <ul className='firstone'>



          <span className='parent' onClick={parentsidemenuclick}>Kriegsbergstraße 11, Stuttgart<i></i></span>
          {menu === 'show' ?


            <>

              <li className={match === '/facility-manager' ? "active" : match === '/' ? "active" : ''}><Link to="/facility-manager"><img src={"/lbs/images/dblogo.png"} alt="" /> <span>Property</span></Link> </li>
              <li className={match === '/facility-manager-modification-requests' ? "active" : ""}><Link to="/facility-manager-modification-requests"><img src={"/lbs/images/Tenant Service.png"} alt="" /> <span>Modifications Requests</span></Link></li>
              <li className={match === '/facility-technical-ticket' ? "active" : ""}><Link to="/facility-technical-ticket"><img src={"/lbs/images//My Tickets.png"} alt="" /> <span>Technical Tickets</span></Link></li>
              <li className={match === '/facility-chat' ? "active" : ""} ><Link to="/facility-chat"><img src={"/lbs/images/dbchat.png"} alt="" /> <span>Chat</span></Link></li>
            </>
            : ''}



          <Outlet />
        </ul>
        <ul className='secoundone'>
          <div className='logoutandsettingmenu'>
            <li className={match === '/genarel-setting' ? "active" : ""}><Link to="/genarel-setting"><img src={"/lbs/images/setting.png"} alt="" /> <span>Setting</span></Link></li>
            <li className={match === '/' ? "active" : ""}><Link to="/"><img src={"/lbs/images/logout.png"} alt="" /> <span>Logout</span></Link></li>
            <li className={match === '/addproperty' ? "active" : ""}><Link to="/addproperty"><img src={"/lbs/images/3 User.png"} alt="" /> <span>Add new Property</span></Link></li>

          </div>
        </ul>
      </div>
    </>
  )
}
