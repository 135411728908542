import React from 'react'
import { Link } from 'react-router-dom'

export default function technicalticket() {
  return (
    <>
      <div className="dashboard">
      <div className="titleanddetail">Technical Tickets</div>
            <div className="col-md-12">
           
                <div className="filterlist">
                    <ul>
                        <li className="active"><a href="#">Open</a></li>
                        <li><a href="#">Close</a></li>
                        <li><a href="#">In Progress</a></li>
                 
                    </ul>
                </div>
                <div className="tenantsTablemyticket" >
                    <table className="table teantsTableinpropertydetails myticket">
                        <thead>
                          <tr>
                            <th scope="col">Requester</th>
                            <th scope="col">Subject</th>
                            <th scope="col">Status</th>
                            <th scope="col">Last Message</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row"><img src={"/lbs/images/profile.png"} alt=""/>
                                <span className="moredetails">
                                <div className="name">David Smith</div>
                                <div className="email">davidsmith@gmail.com</div>
                            </span>
                        </th>
                            <td >Ac sit etiam velit, consequat est augue commodo non.</td>
                            <td><button className="status-btn in-progress">In Progress</button></td>
                            <td>4 seconds ago</td>
                            <td><i className="fa-solid fa-ellipsis"></i></td>
                          </tr>
                         
          
            
                          
                        </tbody>
                      </table>
                </div>
            </div>
        </div>
    </>
  )
}
